/* eslint-disable no-underscore-dangle */
import { VFC, useEffect, useState, useMemo, useCallback, useRef } from "react";
import {
  Stack,
  SxProps,
  Theme,
  Box,
  IconButton,
  Fab,
  Checkbox,
  ThemeProvider,
  Button,
  CircularProgress,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import LocationDisabledIcon from "@mui/icons-material/LocationDisabled";
import LayersIcon from "@mui/icons-material/Layers";

import mapboxgl, { LngLat, PointLike } from "mapbox-gl";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "../TrafficSafetyUserApp.css";
import { Geometry, GeoJsonProperties } from "geojson";
import HeaderMenu from "./HeaderMenu";
import DetailedView, { CausesList } from "./DetailedView";
import LayersView, { LayerIcon, LayerIconList } from "./LayersView";

import ColorTheme from "../themes/TrafficSafetyUserTheme";
import SideMenuComponent from "./SideMenu";
import useNotifications from "../hooks/useNotifications";
import HelpDialog from "./HelpDialog";
import TermsOfUseDialog from "./TermsOfUseDialog";

import HazardousPointRegistrationMenu from "./HazardousPointRegistrationView";
import useTrafficSafetyWebAPI, {
  LayerContent,
  EvaluationItem,
  layersContent,
  CreateFeatureContent,
  EvaluateContent,
  EvaluationsContent,
} from "../hooks/useTrafficSafetyWebAPI";
import hazardousPointIcon from "../images/hazardousPoint1.png";
import hazardmarkerIcon from "../images/hazardmarker.png";
import userHazardouspointIcon from "../images/userHazardouspoints.png";
import schoolRouteHazardousIcon from "../images/schoolRouteHazardousIcon.png";
import addicon from "../images/addicon.svg";
import myLocationIcon from "../images/mylocation.svg";
import stopSignalIcon from "../images/stopSignalIcon.png";
import zone30sIcon from "../images/zone30s.png";
import bicycleLanesIcon from "../images/bicycleLanes.png";
import crosswalkIcon from "../images/crosswalk.png";

import bgImage from "../images/iconImage.png";
import HazardouspointsHighlight from "../images/Hazardouspoints-highlight.png";

import {
  useAuth,
  TrafficSafetyAuthContext,
} from "../contexts/TrafficSafetyAuthContext";
import { TrafficSafetyTransformRequest } from "../functions/TrafficSafety";

type MapStatus = "not_loaded" | "loading" | "loaded" | "error";

export type Indicator = {
  id: string;
  code: string;
  isThink: boolean;
};

type Icon = {
  name: string;
  icon: string;
};

type memo = {
  code: string;
  value: string;
};

const TrafficSafetyUserApp: VFC = function TrafficSafetyUserApp() {
  // Context
  const [{ status: mapboxAccessToken, domain, uuid }] =
    useAuth() as TrafficSafetyAuthContext;

  // WebAPI
  const [
    ,
    {
      createFeature,
      putEvaluate,
      getEvaluations,
      getLayers,
      getFeaturesCollection,
    },
  ] = useTrafficSafetyWebAPI(domain);

  const mapStyleUrl = useMemo(() => `api/styles`, []);
  // const mapStyleUrl = useMemo(() => `http://localhost:7071/api/styles`, []);

  // Notify
  const [notifications, notify, toastClose] = useNotifications();
  const offLayer: string[] = [
    "gsibv-vectortile-layer-2138",
    "gsibv-vectortile-layer-2139",
    "gsibv-vectortile-layer-2140",
    "gsibv-vectortile-layer-2141",
    "gsibv-vectortile-layer-2142",
    "gsibv-vectortile-layer-2143",
    "gsibv-vectortile-layer-2144",
    "gsibv-vectortile-layer-2145",
    "gsibv-vectortile-layer-2146",
    "gsibv-vectortile-layer-2147",
    "gsibv-vectortile-layer-2148",
    "gsibv-vectortile-layer-2149",
    "gsibv-vectortile-layer-2150",
    "gsibv-vectortile-layer-2151",
    "gsibv-vectortile-layer-2152",
    "gsibv-vectortile-layer-2153",
    "gsibv-vectortile-layer-2154",
    "gsibv-vectortile-layer-2155",
    "gsibv-vectortile-layer-2156",
    "gsibv-vectortile-layer-2157",
    "gsibv-vectortile-layer-2158",
    "gsibv-vectortile-layer-2159",
    "gsibv-vectortile-layer-2160",
    "gsibv-vectortile-layer-2161",
    "gsibv-vectortile-layer-2162",
    "gsibv-vectortile-layer-2163",
    "gsibv-vectortile-layer-2164",
    "gsibv-vectortile-layer-2165",
    "gsibv-vectortile-layer-2166",
    "gsibv-vectortile-layer-2167",
    "gsibv-vectortile-layer-2168",
    "gsibv-vectortile-layer-2169",
    "gsibv-vectortile-layer-2170",
    "gsibv-vectortile-layer-2171",
    "gsibv-vectortile-layer-2172",
    "gsibv-vectortile-layer-2173",
    "gsibv-vectortile-layer-2174",
    "gsibv-vectortile-layer-2175",
    "gsibv-vectortile-layer-2176",
    "gsibv-vectortile-layer-2177",
    "gsibv-vectortile-layer-2178",
    "gsibv-vectortile-layer-2179",
    "gsibv-vectortile-layer-2180",
    "gsibv-vectortile-layer-2181",
    "gsibv-vectortile-layer-2182",
    "gsibv-vectortile-layer-2183",
    "gsibv-vectortile-layer-2184",
    "gsibv-vectortile-layer-2185",
    "gsibv-vectortile-layer-2186",
    "gsibv-vectortile-layer-2187",
    "gsibv-vectortile-layer-2188",
    "gsibv-vectortile-layer-2189",
    "gsibv-vectortile-layer-2190",
    "gsibv-vectortile-layer-2191",
    "gsibv-vectortile-layer-2192",
    "gsibv-vectortile-layer-2193",
    "gsibv-vectortile-layer-2194",
    "gsibv-vectortile-layer-2195",
    "gsibv-vectortile-layer-2196",
    "gsibv-vectortile-layer-2197",
    "gsibv-vectortile-layer-2198",
    "gsibv-vectortile-layer-2199",
    "gsibv-vectortile-layer-2200",
    "gsibv-vectortile-layer-2201",
    "gsibv-vectortile-layer-2202",
    "gsibv-vectortile-layer-2203",
    "gsibv-vectortile-layer-2204",
    "gsibv-vectortile-layer-2205",
    "gsibv-vectortile-layer-2206",
    "gsibv-vectortile-layer-2207",
    "gsibv-vectortile-layer-2208",
    "gsibv-vectortile-layer-2209",
    "gsibv-vectortile-layer-2210",
    "gsibv-vectortile-layer-2211",
    "gsibv-vectortile-layer-2212",
    "gsibv-vectortile-layer-2213",
    "gsibv-vectortile-layer-2214",
    "gsibv-vectortile-layer-2215",
    "gsibv-vectortile-layer-2216",
    "gsibv-vectortile-layer-2217",
    "gsibv-vectortile-layer-2218",
    "gsibv-vectortile-layer-2219",
    "gsibv-vectortile-layer-2220",
    "gsibv-vectortile-layer-2221",
    "gsibv-vectortile-layer-2222",
    "gsibv-vectortile-layer-2223",
    "gsibv-vectortile-layer-2224",
    "gsibv-vectortile-layer-2225",
    "gsibv-vectortile-layer-2226",
    "gsibv-vectortile-layer-2227",
    "gsibv-vectortile-layer-2228",
    "gsibv-vectortile-layer-2229",
    "gsibv-vectortile-layer-2230",
    "gsibv-vectortile-layer-2231",
    "gsibv-vectortile-layer-2232",
    "gsibv-vectortile-layer-2233",
    "gsibv-vectortile-layer-2234",
    "gsibv-vectortile-layer-2235",
    "gsibv-vectortile-layer-2236",
    "gsibv-vectortile-layer-2237",
    "gsibv-vectortile-layer-2238",
    "gsibv-vectortile-layer-2239",
    "gsibv-vectortile-layer-2240",
    "gsibv-vectortile-layer-2241",
    "gsibv-vectortile-layer-2242",
    "gsibv-vectortile-layer-2243",
    "gsibv-vectortile-layer-2244",
    "gsibv-vectortile-layer-2245",
    "gsibv-vectortile-layer-2246",
    "gsibv-vectortile-layer-2247",
    "gsibv-vectortile-layer-2248",
    "gsibv-vectortile-layer-2249",
    "gsibv-vectortile-layer-2250",
    "gsibv-vectortile-layer-2251",
    "gsibv-vectortile-layer-2252",
    "gsibv-vectortile-layer-2253",
    "gsibv-vectortile-layer-2254",
    "gsibv-vectortile-layer-2255",
    "gsibv-vectortile-layer-2256",
    "gsibv-vectortile-layer-2257",
    "gsibv-vectortile-layer-2258",
    "gsibv-vectortile-layer-2259",
    "gsibv-vectortile-layer-2260",
    "gsibv-vectortile-layer-2261",
    "gsibv-vectortile-layer-2262",
    "gsibv-vectortile-layer-2263",
    "gsibv-vectortile-layer-2264",
    "gsibv-vectortile-layer-2265",
    "gsibv-vectortile-layer-2266",
    "gsibv-vectortile-layer-2267",
    "gsibv-vectortile-layer-2268",
    "gsibv-vectortile-layer-2269",
    "gsibv-vectortile-layer-2270",
    "gsibv-vectortile-layer-2271",
    "gsibv-vectortile-layer-2272",
    "gsibv-vectortile-layer-2273",
    "gsibv-vectortile-layer-2274",
    "gsibv-vectortile-layer-2275",
    "gsibv-vectortile-layer-2276",
    "gsibv-vectortile-layer-2277",
    "gsibv-vectortile-layer-2278",
    "gsibv-vectortile-layer-2279",
    "gsibv-vectortile-layer-2280",
    "gsibv-vectortile-layer-2281",
    "gsibv-vectortile-layer-2282",
    "gsibv-vectortile-layer-2283",
    "gsibv-vectortile-layer-2284",
    "gsibv-vectortile-layer-2285",
    "gsibv-vectortile-layer-2286",
    "gsibv-vectortile-layer-2287",
    "gsibv-vectortile-layer-2288",
    "gsibv-vectortile-layer-2289",
    "gsibv-vectortile-layer-2290",
    "gsibv-vectortile-layer-2291",
    "gsibv-vectortile-layer-2292",
    "gsibv-vectortile-layer-2293",
    "gsibv-vectortile-layer-2294",
    "gsibv-vectortile-layer-2115",
    "gsibv-vectortile-layer-2116",
    "gsibv-vectortile-layer-2117",
    "gsibv-vectortile-layer-2118",
    "gsibv-vectortile-layer-2119",
    "gsibv-vectortile-layer-2120",
    "gsibv-vectortile-layer-2121",
    "gsibv-vectortile-layer-2122",
  ];

  const [icons] = useState<Icon[]>([
    { name: "事故危険地点", icon: hazardmarkerIcon },
    { name: "利用者提供危険地点", icon: userHazardouspointIcon },
    { name: "通学路危険地点", icon: schoolRouteHazardousIcon },
    { name: "一時停止", icon: stopSignalIcon },
    { name: "自転車通行帯", icon: bicycleLanesIcon },
    { name: "ゾーン30", icon: zone30sIcon },
    { name: "横断歩道", icon: crosswalkIcon }
  ]);

  // MenuOpen
  const [isSideMenu, setIsSideMenu] = useState<boolean>(false);
  const [isTermsOfUseDialog, setIsTermsOfUseDialog] = useState<boolean>(false);
  const [isHelpChangeTermsOfUseDialog, setIsHelpChangeTermsOfUseDialog] =
    useState<boolean>(false);
  const [isHelpDialog, setIsHelpDialog] = useState<boolean>(
    !localStorage.getItem("AcceptanceOfTermsOfUse")
  );

  const [
    isHazardousPointRegistrationMenu,
    setIsHazardousPointRegistrationMenu,
  ] = useState<boolean>(false);
  const [isDetailedView, setIsDetailedView] = useState<boolean>(false);
  const [isLayersView, setIsLayersView] = useState<boolean>(false);
  const [isHorizontalScreen, setIsHorizontalScreen] = useState<boolean>(true);

  // Map
  const [mapInstance, setMapInstance] = useState<
    mapboxgl.Map | null | undefined
  >();
  const mapContainer = "map-container";
  const [mapStatus, setMapStatus] = useState<MapStatus>("not_loaded");
  const [mapWidth] = useState<string>(`${window.innerWidth}px`);
  const [mapHeight] = useState<string>(`${window.innerHeight - 60}px`);
  const [lngLat, setLngLat] = useState<LngLat>(
    new LngLat(137.386131621, 34.698738573)
  );
  const [layerInfos, setLayerInfos] = useState<layersContent>();
  const layerInfosRef = useRef<layersContent>();
  layerInfosRef.current = layerInfos;
  const [selectedFeatureId, setSelectedFeatureId] = useState<string>();
  const [selectedLayerId, setSelectedLayerId] = useState<string>();
  const [popup, setPopup] = useState<mapboxgl.Popup>();
  const [cashFeatures, setCashFeatures] = useState<{
    [key: number]: GeoJSON.FeatureCollection<Geometry, GeoJsonProperties>;
  }>({});

  // Marker
  const [myLocationMarker, setMyLocationMarker] = useState<mapboxgl.Marker>();

  const [indicators, setIndicators] = useState<Indicator[]>([]);
  const indicatorsRef = useRef<Indicator[]>([]);
  indicatorsRef.current = indicators;
  const [registrationMode, setRegistrationMode] = useState<boolean>(false);
  const registrationModeRef = useRef(false); //  ref オブジェクト作成する。イベントリスナでstateの最新の値を呼び出すため
  registrationModeRef.current = registrationMode; // registrationModeを.currentプロパティへ保持する

  const [selectedFeatureProperties, setSelectedFeatureProperties] = useState<
    CausesList[]
  >([]);

  const [dangerReasons, setDangerReasons] = useState<EvaluationItem[]>([]);

  const [addLocation, setAddLocation] = useState<mapboxgl.LngLat>();

  const [evaluation, setEvaluation] = useState<EvaluationsContent>();

  const [height, setHeight] = useState<number>(0);
  const heightRef = useRef(0);
  heightRef.current = height;

  const handleReloadMap = useCallback(() => {
    if (mapInstance != null && mapStatus === "loaded") {
      mapInstance.setStyle(mapStyleUrl);
    }
  }, [mapInstance, mapStatus, mapStyleUrl]);

  const updateWidth = () => {
    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
      setIsHorizontalScreen(window.innerWidth < window.innerHeight);
    }
  };
  const [isTextLayer, setIsTextLayer] = useState<boolean>(false);
  const [isIndicator, setIsIndicator] = useState<boolean>(true);
  // layerIcon
  const [layerIconList, setLayerIconList] = useState<LayerIconList>({
    layers: [],
  });
  const [layerIconListMaster, setLayerIconListMaster] = useState<LayerIconList>(
    {
      layers: [],
    }
  );
  const layerIconListMasterRef = useRef<LayerIconList>();
  layerIconListMasterRef.current = layerIconListMaster;

  // 画面の縦横変更時の処理
  useEffect(() => {
    window.addEventListener(`resize`, updateWidth, {
      capture: false,
      passive: true,
    });
    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
      setIsHorizontalScreen(window.innerWidth < window.innerHeight);
    }
    return () => window.removeEventListener(`resize`, updateWidth);
  }, []);

  // 地図表示後の初期化処理
  useEffect(() => {
    if (layerInfos == null) {
      const func = async () => {
        try {
          const layerInfo = await getLayers?.();
          const userLayer = layerInfo?.layers.find(
            (x) => x.name === "利用者提供危険地点"
          );
          if (userLayer) setDangerReasons(userLayer.evaluationItems);

          setLayerInfos(layerInfo);
          if (uuid) {
            setEvaluation(await getEvaluations?.(uuid));
          }
          const localPropertie: {
            [key: string]: GeoJSON.FeatureCollection<
              Geometry,
              GeoJsonProperties
            >;
          } = {};

          layerInfo?.layers?.map(async (layer) => {
            localPropertie[layer.id] = (await getFeaturesCollection?.(
              layer.id
            )) as GeoJSON.FeatureCollection<Geometry, GeoJsonProperties>;
          });
          const layersIcon: LayerIcon[] = [];
          layerInfo?.layers.forEach((layer) => {
            layersIcon.push({
              id: String(layer.id),
              name: layer.name,
              icon: icons.find((x) => x.name === layer.name)?.icon as string,
              visible: layer.isVisible,
            });
          });
          setLayerIconList({ layers: layersIcon });
          setLayerIconListMaster({ layers: layersIcon });
          if (localPropertie) setCashFeatures(localPropertie);
        } catch {
          notify({
            type: "error",
            title: "エラー",
            message: "WebAPIの取得に失敗しました。",
          });
        }
      };
      void func();
    }
  }, [
    getEvaluations,
    getFeaturesCollection,
    getLayers,
    icons,
    layerIconList.layers,
    layerIconListMaster,
    layerIconListMaster.layers,
    layerInfos,
    mapInstance,
    notify,
    uuid,
  ]);

  useEffect(() => {
    if (mapStatus === "not_loaded" && mapboxAccessToken && mapContainer) {
      // GPS取得地点
      let latitude: number = lngLat.lat;
      let longitude: number = lngLat.lng;
      navigator.geolocation.getCurrentPosition((position) => {
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
        setLngLat(new LngLat(longitude, latitude));
        if (map) {
          const myLocationElement = document.createElement("div");
          myLocationElement.className = "marker";
          myLocationElement.style.backgroundImage = `url(${myLocationIcon})`;
          myLocationElement.style.width = `24px`;
          myLocationElement.style.height = `24px`;
          myLocationElement.style.backgroundSize = "100%";
          map.panTo([longitude, latitude], { duration: 0 });
          setMyLocationMarker(
            new mapboxgl.Marker(myLocationElement)
              .setLngLat([longitude, latitude])
              .addTo(map)
          );
        }
      });

      const map = new mapboxgl.Map({
        container: mapContainer,
        accessToken: mapboxAccessToken,
        transformRequest: TrafficSafetyTransformRequest(),
        center: [longitude, latitude],
        zoom: 15,
        maxZoom: 17.99,
        minZoom: 4,
        attributionControl: false,
      });

      if (map) {
        setMapInstance(map);
        setMapStatus("loading");

        map.setStyle(mapStyleUrl);

        // 地図の回転を抑止
        map.dragRotate.disable();
        map.touchZoomRotate.disableRotation();
        map.addControl(new mapboxgl.AttributionControl(), "bottom-left");

        // アイコン画像設定
        map.loadImage(hazardousPointIcon, (error, image) => {
          if (error) throw error;

          map.addImage("custom-marker", image as ImageBitmap);
        });
        map.loadImage(userHazardouspointIcon, (error, image) => {
          if (error) throw error;

          map.addImage("userHazardouspointIcon", image as ImageBitmap);

          const ctx = (
            document.getElementById("canvas") as HTMLCanvasElement
          ).getContext("2d");
          if (ctx) ctx.drawImage(image as ImageBitmap, 0, 0);

          const indicator = (
            document.getElementById("indicator") as HTMLCanvasElement
          ).getContext("2d");
          if (indicator) indicator.drawImage(image as ImageBitmap, 0, 0);
        });

        map.loadImage(HazardouspointsHighlight, (error, image) => {
          if (error) throw error;

          map.addImage("HazardouspointsHighlight", image as ImageBitmap);
        });

        map.loadImage(stopSignalIcon, (error, image) => {
          if (error) throw error;

          map.addImage("stopSignalIcon", image as ImageBitmap);
        });

        map.loadImage(crosswalkIcon, (error, image) => {
          if (error) throw error;

          map.addImage("crosswalkIcon", image as ImageBitmap);
        });

        // Mapイベント
        map.on("load", () => {
          setMapStatus("loaded");
          handleReloadMap();
          // layer表示設定
          if (map && layerIconListMasterRef?.current?.layers.length !== 0) {
            layerIconListMasterRef?.current?.layers.forEach((layer) => {
              const index = layerIconListMasterRef?.current?.layers.findIndex(
                (x) => x.id === String(layer.id)
              ) as number;
              if (layer.visible) {
                map.setLayoutProperty(
                  String(layer.id),
                  "visibility",
                  "visible"
                );
                layerIconList.layers[index] = {
                  id: layerIconListMasterRef?.current?.layers[index]
                    .id as string,
                  name: layerIconListMasterRef?.current?.layers[index]
                    .name as string,
                  icon: layerIconListMasterRef?.current?.layers[index]
                    .icon as string,
                  visible: true,
                };
              } else {
                map.setLayoutProperty(String(layer.id), "visibility", "none");
                layerIconList.layers[index] = {
                  id: layerIconListMasterRef?.current?.layers[index]
                    .id as string,
                  name: layerIconListMasterRef?.current?.layers[index]
                    .name as string,
                  icon: layerIconListMasterRef?.current?.layers[index]
                    .icon as string,
                  visible: false,
                };
              }
            });
            setIsIndicator(false);
          }
        });

        // フィーチャをクリックした時の挙動
        map.on("click", (e) => {
          toastClose();
          if (registrationModeRef.current === true) return;
          setSelectedFeatureProperties([]);
          setSelectedFeatureId(undefined);
          setSelectedLayerId(undefined);
          // フィーチャ検索
          const bbox = [
            [e.point.x - 5, e.point.y - 5],
            [e.point.x + 5, e.point.y + 5],
          ];
          const selectedFeatures = map.queryRenderedFeatures(
            bbox as [PointLike, PointLike],
            {
              layers: layerInfosRef?.current?.layers.map(
                (layer: LayerContent) => layer.id.toString()
              ),
            }
          );

          if (selectedFeatures.length === 0) {
            setIsDetailedView(false);
            layerInfosRef?.current?.layers.forEach((layer: LayerContent) => {
              map.setFilter(`${layer.id}-highlighted`, ["in", "id", ""]);
            });
            return;
          }
          const featureProperties = Object.fromEntries(
            Object.entries(selectedFeatures[0]?.properties ?? {}).filter(
              ([key]) => !key.startsWith("id")
            )
          );

          // 必要なプロパティを取得
          const musicians: Array<CausesList> = [];
          const accidents: Array<memo> = [];
          let isPopup = false;
          JSON.parse(
            JSON.stringify(featureProperties, null, "  "),
            (key, value) => {
              if (key) {
                if (key.indexOf("evalCount_") !== -1) {
                  const Causes: CausesList = {
                    code: key.replace("evalCount_", ""),
                    count: value as number,
                  };
                  musicians.push(Causes);
                } else if (key.indexOf("memo") !== -1) {
                  const Causes: memo = {
                    code: key,
                    value: value as string,
                  };
                  accidents.push(Causes);
                } else if (key.indexOf("popup") !== -1) {
                  if (value === 1) {
                    isPopup = true;
                  }
                }
              }
            }
          );

          // ハイライトを表示
          setSelectedFeatureProperties(musicians);
          if (selectedFeatures[0].properties) {
            if (selectedFeatures[0].properties.id === undefined) return;
            setSelectedFeatureId(selectedFeatures[0].properties.id as string);
            const fips = selectedFeatures[0].properties.id as string;

            layerInfosRef?.current?.layers.forEach((layer: LayerContent) => {
              map.setFilter(`${layer.id}-highlighted`, [
                "in",
                "id",
                selectedFeatures[0].layer.id === layer.id.toString()
                  ? fips
                  : "",
              ]);
            });
          }
          setSelectedLayerId(selectedFeatures[0].layer.id);

          const detailedView = document.getElementById(
            "HazardousPointRegistration"
          ) as HTMLElement;
          const mapElement = document.getElementById(
            mapContainer
          ) as HTMLElement;

          // 地図の移動位置を決める
          let coordinates = map.project([e.lngLat.lng, e.lngLat.lat]);
          if (selectedFeatures[0].geometry.type === "Point") {
            const geometryCoordinate = selectedFeatures[0].geometry.coordinates;

            coordinates = map.project([
              geometryCoordinate[0],
              geometryCoordinate[1],
            ]);
          }

          let offsetcoordinates;

          setIsHazardousPointRegistrationMenu(false);
          if (musicians.length !== 0) {
            setIsDetailedView(true);
            offsetcoordinates = map.unproject([
              coordinates.x,
              coordinates.y +
                (detailedView.clientHeight - mapElement.clientHeight) / 2 +
                mapElement.clientHeight / 2,
            ]);
          } else {
            setIsDetailedView(false);
            offsetcoordinates = map.unproject([coordinates.x, coordinates.y]);
          }

          // 吹き出しを表示
          if (accidents.length !== 0 && isPopup)
            if (accidents[0].value) {
              const layerInfo = layerInfosRef?.current?.layers.find(
                (x) => x.id === 1
              ) as LayerContent;
              const propertySchemas = layerInfo?.propertySchemas;
              let description = "";
              if (propertySchemas) {
                accidents.forEach((accident) => {
                  description += `${accident.value}`;
                });
              }
              if (accidents !== undefined) {
                const featureLngLat = map.unproject([
                  coordinates.x,
                  coordinates.y,
                ]);
                setPopup(
                  new mapboxgl.Popup({
                    closeButton: false,
                    className: "popup",
                    anchor: "bottom",
                  })
                    .setMaxWidth("50%")
                    .setLngLat(featureLngLat)
                    .setHTML(description)
                    .addTo(map)
                );
              }
            }
          setIsLayersView(false);
          // 地図を移動する
          map.flyTo({
            animate: true,
            zoom: map.getZoom(),
            center: [offsetcoordinates.lng, offsetcoordinates.lat],
          });
        });

        map.on("move", () => {
          toastClose();
          if (registrationModeRef.current === false) return;
          const pin = map?.getCenter();
          if (pin) {
            const coordinates = map?.project([pin.lng, pin.lat]);
            if (map) {
              if (coordinates) {
                const offsetcoordinates = map?.unproject([
                  coordinates.x,
                  heightRef.current - 44,
                ]);

                setAddLocation(
                  new mapboxgl.LngLat(
                    offsetcoordinates.lng,
                    offsetcoordinates.lat
                  )
                );
              }
            }
          }
        });
      } else {
        throw new Error();
      }
    }
  }, [
    handleReloadMap,
    layerIconList.layers,
    layerIconListMaster.layers,
    layerInfos?.layers,
    lngLat.lat,
    lngLat.lng,
    mapStatus,
    mapStyleUrl,
    mapboxAccessToken,
    toastClose,
  ]);

  const mapSxProps: SxProps<Theme> = {
    width: mapWidth,
    height: mapHeight,
    position: "absolute",
  };

  // レイヤ表示ボトムメニューの表示切替
  const layerMenuDisplayChange = () => {
    // イベントが存在するか
    setIsTextLayer(!isTextLayer);
    setIsLayersView(!isLayersView);
    setIsHazardousPointRegistrationMenu(false);
    setRegistrationMode(false);
    setIsDetailedView(false);
    popup?.remove();
    if (layerInfos)
      if (mapInstance)
        layerInfos.layers.forEach((layer: LayerContent) => {
          mapInstance.setFilter(`${layer.id}-highlighted`, ["in", "id", ""]);
        });
  };

  // 地点を登録するボトムメニューの表示切替
  const addMenuDisplayChange = () => {
    notify({
      type: "info",
      title: "",
      message: "地図をスクロールして登録地点を調整してください。",
    });

    setIsHazardousPointRegistrationMenu(!registrationMode);
    setRegistrationMode(!registrationMode);
    if (!registrationMode) {
      const pin = mapInstance?.getCenter();
      if (pin) {
        const coordinates = mapInstance?.project([pin.lng, pin.lat]);
        if (mapInstance) {
          if (coordinates) {
            const detailedView = document.getElementById(
              "HazardousPointRegistration"
            ) as HTMLElement;
            if (detailedView) {
              const addHeight =
                (window.innerHeight - detailedView.clientHeight) / 2;

              setHeight(addHeight);

              const offsetcoordinates = mapInstance?.unproject([
                coordinates.x,
                addHeight - 44,
              ]);

              setAddLocation(
                new mapboxgl.LngLat(
                  offsetcoordinates.lng,
                  offsetcoordinates.lat
                )
              );
            }
          }
        }
      }
    }
  };

  // 登録モード解除
  const registrationModeCancellation = () => {
    // 追加モード
    setIsHazardousPointRegistrationMenu(false);
    setRegistrationMode(false);
  };

  // 現在位置への移動
  const MoveToCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      if (mapInstance) {
        if (myLocationMarker) {
          myLocationMarker.remove();
        }

        const el = document.createElement("div");
        el.className = "marker";
        el.style.backgroundImage = `url(${myLocationIcon})`;
        el.style.width = `24px`;
        el.style.height = `24px`;
        el.style.backgroundSize = "100%";
        setMyLocationMarker(
          new mapboxgl.Marker(el)
            .setLngLat([position.coords.longitude, position.coords.latitude])
            .addTo(mapInstance)
        );
        if (isHazardousPointRegistrationMenu === true) {
          const innerlatlng1 = mapInstance.unproject([
            window.innerWidth,
            window.innerHeight / 2,
          ]);
          const innerlatlng2 = mapInstance.unproject([
            window.innerWidth,
            window.innerHeight / 4,
          ]);
          const innerlng = innerlatlng1.lat - innerlatlng2.lat;
          mapInstance.flyTo({
            animate: true,
            zoom: mapInstance.getZoom(),
            center: [
              position.coords.longitude,
              position.coords.latitude + innerlng,
            ],
          });
        } else if (isLayersView === true) {
          const innerlatlng1 = mapInstance.unproject([
            window.innerWidth,
            window.innerHeight / 2,
          ]);
          const innerlatlng2 = mapInstance.unproject([
            window.innerWidth,
            window.innerHeight / 4,
          ]);
          const innerlng = innerlatlng1.lat - innerlatlng2.lat;
          mapInstance.flyTo({
            animate: true,
            zoom: mapInstance.getZoom(),
            center: [
              position.coords.longitude,
              position.coords.latitude + innerlng,
            ],
          });
        } else if (isDetailedView === true) {
          const innerlatlng1 = mapInstance.unproject([
            window.innerWidth,
            window.innerHeight / 2,
          ]);
          const innerlatlng2 = mapInstance.unproject([
            window.innerWidth,
            window.innerHeight / 4,
          ]);
          const innerlng = innerlatlng1.lat - innerlatlng2.lat;
          mapInstance.flyTo({
            animate: true,
            zoom: mapInstance.getZoom(),
            center: [
              position.coords.longitude,
              position.coords.latitude + innerlng,
            ],
          });
        } else {
          mapInstance.flyTo({
            animate: true,
            zoom: mapInstance.getZoom(),
            center: [position.coords.longitude, position.coords.latitude],
          });
        }
      }
    });
  };

  return (
    <ThemeProvider theme={ColorTheme}>
      {isHorizontalScreen ? (
        ""
      ) : (
        <Box>
          <Box
            sx={{
              bgcolor: "#534bae",
              height: `${window.innerHeight}px`,
              width: `${window.innerWidth}px`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={bgImage}
              style={{
                height: window.innerHeight / 4,
              }}
              loading="lazy"
              alt="アイコン"
            />
            <h1 style={{ color: "#FFFFFF" }}>
              このアプリは 縦画面でご利用ください
            </h1>
          </Box>
        </Box>
      )}
      <div style={{ display: isHorizontalScreen ? "" : "none" }}>
        <Stack id="header" direction="column">
          <HeaderMenu
            mapName={
              process.env.REACT_APP_ENVIRONMENT === "test"
                ? "豊橋交通安全アプリT"
                : "豊橋交通安全アプリ"
            }
            onConfirm={(bool: boolean) => {
              setIsSideMenu(bool);
            }}
            onHelp={(bool: boolean) => {
              setIsHelpDialog(bool);
            }}
          />
        </Stack>
        <Stack direction="column">
          <Box id="map-container" sx={mapSxProps} />
        </Stack>
        <SideMenuComponent
          key="SideMenuComponentContent"
          isSideMenu={isSideMenu}
          onConfirm={(bool: boolean) => {
            setIsSideMenu(bool);
          }}
          onPositionCurrent={(bool: boolean) => {
            setIsSideMenu(bool);
            MoveToCurrentPosition();
          }}
          onRegistration={(bool: boolean) => {
            setIsSideMenu(bool);
            addMenuDisplayChange();
            setIsLayersView(false);
            setIsDetailedView(false);
            popup?.remove();
            if (layerInfos)
              if (mapInstance)
                layerInfos.layers.forEach((layer: LayerContent) => {
                  mapInstance.setFilter(`${layer.id}-highlighted`, [
                    "in",
                    "id",
                    "",
                  ]);
                });
          }}
          onTermsOfUseDialog={(bool: boolean) => {
            setIsSideMenu(bool);
            setIsTermsOfUseDialog(true);
          }}
          onMapTextView={(bool: boolean) => {
            setIsSideMenu(bool);
            setIsLayersView(!isLayersView);
            setIsHazardousPointRegistrationMenu(false);
            setRegistrationMode(false);
            setIsDetailedView(false);
            popup?.remove();
            if (layerInfos)
              if (mapInstance)
                layerInfos.layers.forEach((layer: LayerContent) => {
                  mapInstance.setFilter(`${layer.id}-highlighted`, [
                    "in",
                    "id",
                    "",
                  ]);
                });
          }}
        />
        <HelpDialog
          isSideMenu={isHelpDialog}
          onClose={(bool: boolean) => {
            setIsHelpDialog(bool);
            localStorage.setItem("AcceptanceOfTermsOfUse", "true");
          }}
          onShowHelp={() => {
            localStorage.setItem("AcceptanceOfTermsOfUse", "true");
            setIsTermsOfUseDialog(true);
            setIsHelpChangeTermsOfUseDialog(true);
          }}
        />
        <TermsOfUseDialog
          isSideMenu={isTermsOfUseDialog}
          onClose={(bool: boolean) => {
            setIsTermsOfUseDialog(bool);
            if (isHelpChangeTermsOfUseDialog) {
              setIsHelpChangeTermsOfUseDialog(false);
            }
          }}
        />

        <HazardousPointRegistrationMenu
          open={isHazardousPointRegistrationMenu}
          dangerReason={dangerReasons}
          onClose={(bool: boolean) => {
            toastClose();
            setIsHazardousPointRegistrationMenu(bool);
            registrationModeCancellation();
          }}
          onCreate={async (bool: boolean, causes: number[]) => {
            try {
              setIsDetailedView(bool);

              setIsHazardousPointRegistrationMenu(bool);
              registrationModeCancellation();

              const geojsonSource = mapInstance?.getSource(
                "利用者提供危険地点"
              ) as mapboxgl.GeoJSONSource;

              const layer = layerInfos?.layers.find(
                (x) => x.name === "利用者提供危険地点"
              );

              const addLayerId = layer?.id;

              if (addLayerId) {
                if (addLocation) {
                  if (mapInstance) {
                    if (addLocation) {
                      const addCoordinates = [addLocation.lng, addLocation.lat];
                      const id = uuidv4();
                      const createFeatureContent: CreateFeatureContent = {
                        type: "Feature",
                        geometry: {
                          type: "Point",
                          coordinates: [addCoordinates[0], addCoordinates[1]],
                        },
                        id,
                      };

                      const layerId = layerInfos?.layers
                        .find(
                          (value: LayerContent) =>
                            value.name === "利用者提供危険地点"
                        )
                        ?.id.toString();
                      if (layerId)
                        if (addLayerId) {
                          const features =
                            cashFeatures[Number(layerId)]?.features ?? [];

                          const evaluationItemProperty =
                            layer.evaluationItems.map((value) =>
                              value.code.toString()
                            );

                          if (addLocation) {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            const propertie: { [key: string]: unknown } = {};
                            evaluationItemProperty.forEach((value) => {
                              const key = `evalCount_${value}_agree`;

                              propertie[key] = causes.some(
                                (x) => x.toString() === value
                              )
                                ? 1
                                : 0;
                              propertie[`evalCount_${value}_disagree`] = 0;
                            });

                            const localPropertie: { [key: string]: unknown } =
                              {};
                            evaluationItemProperty.forEach((value) => {
                              const key = `${value}`;

                              if (causes.some((x) => x.toString() === value)) {
                                localPropertie[key] = 1;
                              }
                            });

                            propertie.id = id;
                            features.push({
                              type: "Feature",
                              properties: propertie,
                              geometry: {
                                type: "Point",
                                coordinates: [
                                  addCoordinates[0],
                                  addCoordinates[1],
                                ],
                              },
                            });
                            geojsonSource?.setData({
                              type: "FeatureCollection",
                              features,
                            });

                            const pushLayer = evaluation?.layers.find(
                              (x) => x.layerId === Number(layerId)
                            );
                            if (pushLayer) {
                              const index =
                                evaluation?.layers.indexOf(pushLayer);
                              evaluation?.layers[index as number].features.push(
                                {
                                  featureId: id,
                                  evalItems: localPropertie,
                                }
                              );
                            } else {
                              const evaluationsContent = {
                                layers: [
                                  {
                                    layerId: addLayerId,
                                    features: [
                                      {
                                        featureId: id,
                                        evalItems: localPropertie,
                                      },
                                    ],
                                  },
                                ],
                              } as EvaluationsContent;
                              setEvaluation(evaluationsContent);
                            }
                          }
                          const featureInfo = await createFeature?.(
                            addLayerId,
                            createFeatureContent
                          );

                          causes.map(async (code) => {
                            if (uuid) {
                              const evaluateContent: EvaluateContent = {
                                guid: uuid,
                                code,
                                evaluate: "agree",
                              };
                              // ユーザの評価を更新する
                              if (featureInfo) {
                                if (featureInfo.properties) {
                                  await putEvaluate?.(
                                    addLayerId,
                                    id,
                                    evaluateContent
                                  );
                                }
                              }
                            }
                          });
                        }
                    }
                  }
                }
              }
            } catch (e) {
              const error = e as Error;
              notify({
                type: "error",
                title: "エラー",
                message: `危険地域の登録に失敗しました。${error.message.toString()}`,
              });
            }
          }}
        />

        <LayersView
          open={isLayersView}
          layers={layerIconListMaster}
          onClose={(bool: boolean) => {
            setIsLayersView(bool);
            popup?.remove();
          }}
          onUpdate={(id: string) => {
            if (mapInstance) {
              if (id === "名称") {
                // 地図の名称
                offLayer.forEach((layerId) => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  const visibility = mapInstance.getLayoutProperty(
                    layerId,
                    "visibility"
                  );

                  if (visibility === "none") {
                    mapInstance.setLayoutProperty(
                      layerId,
                      "visibility",
                      "visible"
                    );
                  } else {
                    mapInstance.setLayoutProperty(
                      layerId,
                      "visibility",
                      "none"
                    );
                  }
                });
              } else {
                // ユーザ階層
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const visibility = mapInstance.getLayoutProperty(
                  id,
                  "visibility"
                );
                const index = layerIconList.layers.findIndex(
                  (x) => x.id === id
                );
                if (visibility === "none") {
                  mapInstance.setLayoutProperty(id, "visibility", "visible");
                  layerIconList.layers[index] = {
                    id: layerIconList.layers[index].id,
                    name: layerIconList.layers[index].name,
                    icon: layerIconList.layers[index].icon,
                    visible: true,
                  };
                } else {
                  mapInstance.setLayoutProperty(id, "visibility", "none");
                  layerIconList.layers[index] = {
                    id: layerIconList.layers[index].id,
                    name: layerIconList.layers[index].name,
                    icon: layerIconList.layers[index].icon,
                    visible: false,
                  };
                }
                setLayerIconListMaster({ layers: layerIconList.layers });
              }
            }
          }}
        />
        <DetailedView
          open={isDetailedView}
          featureProperties={selectedFeatureProperties}
          evaluation={evaluation as EvaluationsContent}
          evaluationItems={
            layerInfos?.layers.find((el) => el.id === Number(selectedLayerId))
              ?.evaluationItems as EvaluationItem[]
          }
          selectedFeatureId={selectedFeatureId as string}
          selectedLayerId={selectedLayerId as string} 
          indicators={indicators} 
          isExclusive={
            layerInfos?.layers.find((el) => el.id === Number(selectedLayerId))
              ?.evaluationIsExclusive as boolean
          }
          onConfirm={(featureId: string, code: string, isThink: boolean) => {
            setIndicators([...indicators, { id: featureId, code, isThink }]);
          }}
          onClose={(bool: boolean) => {
            if (layerInfos)
              if (mapInstance)
                layerInfos.layers.forEach((layer: LayerContent) => {
                  mapInstance.setFilter(`${layer.id}-highlighted`, [
                    "in",
                    "id",
                    "",
                  ]);
                });
            setIsDetailedView(bool);
            popup?.remove();
          }}
          onUpdate={(
            layerId: number,
            featureId: string,
            evaluateContent: EvaluateContent,
            evaluateValue: number,
            code: string,
            isExclusive: boolean,
            counterCheck: boolean,
            oppositionCode: string,
            checkedList: number[]
          ) => {
            try {
              const layer: LayerContent = layerInfos?.layers.find(
                (x) => x.id === layerId
              ) as LayerContent;

              const features = cashFeatures[layerId]?.features ?? [];
              const allFeatures = features.filter(
                (x) => x?.properties?.id !== featureId
              );

              const relatedCounties = features.filter(
                (x) => x?.properties?.id === featureId
              );

              if (relatedCounties) {
                if (allFeatures) {
                  const featureProperties = Object.fromEntries(
                    Object.entries(relatedCounties[0]?.properties ?? {})
                  );

                  featureProperties[
                    `evalCount_${evaluateContent.code}_${code}`
                  ] += evaluateValue;
                  if (counterCheck) {
                    featureProperties[
                      `evalCount_${evaluateContent.code}_${oppositionCode}`
                    ] -= evaluateValue;
                  }

                  if (isExclusive) {
                    checkedList.forEach((value: number) => {
                      if (value <= -1)
                        featureProperties[`evalCount_${value}_agree`] -= 1;
                    });
                  }

                  relatedCounties[0].properties = featureProperties;
                  allFeatures.push(relatedCounties[0]);

                  (
                    mapInstance?.getSource(layer.name) as mapboxgl.GeoJSONSource
                  )?.setData({
                    type: "FeatureCollection",
                    features: allFeatures,
                  });

                  const musicians: Array<CausesList> = [];
                  JSON.parse(
                    JSON.stringify(featureProperties, null, "  "),
                    (key, value: number) => {
                      if (key) {
                        if (key.indexOf("evalCount_") !== -1) {
                          const Causes: CausesList = {
                            code: key.replace("evalCount_", ""),
                            count: value,
                          };
                          musicians.push(Causes);
                        }
                      }
                    }
                  );
                  const func = async () => {
                    // ユーザの評価を更新する
                    await putEvaluate?.(
                      Number(layerId),
                      featureId,
                      evaluateContent
                    );
                    const result = indicatorsRef.current.filter(
                      (indicator) =>
                        indicator.id !== featureId ||
                        indicator.code !==
                          `${evaluateContent.code.toString()}_agree`
                    );
                    setIndicators(result);
                  };
                  void func();
                  setSelectedFeatureProperties(musicians);
                }
              }
            } catch (ex) {
              notify({
                type: "error",
                title: "エラー",
                message: "評価の更新に失敗しました",
              });
            }
          }}
          onDisable={(
            layerId: number,
            featureId: string,
            checkedList: number[],
            evaluateContent: EvaluateContent
          ) => {
            try {
              // ローカル更新
              const layer: LayerContent = layerInfos?.layers.find(
                (x) => x.id === layerId
              ) as LayerContent;
              const features = cashFeatures[layerId]?.features ?? [];
              const allFeatures = features.filter(
                (x) => x?.properties?.id !== featureId
              );

              const relatedCounties = features.filter(
                (x) => x?.properties?.id === featureId
              );
              if (relatedCounties) {
                if (allFeatures) {
                  const geojsonSource = mapInstance?.getSource(
                    layer.name
                  ) as mapboxgl.GeoJSONSource;

                  const featureProperties = Object.fromEntries(
                    Object.entries(relatedCounties[0]?.properties ?? {})
                  );
                  checkedList.forEach((value: number) => {
                    featureProperties[`evalCount_${value}_agree`] -= 1;
                  });
                  if (evaluateContent.evaluate === "agree")
                    if (
                      featureProperties[
                        `evalCount_${evaluateContent.code}_agree`
                      ] === undefined
                    ) {
                      featureProperties[
                        `evalCount_${evaluateContent.code}_agree`
                      ] = 1;
                    } else {
                      featureProperties[
                        `evalCount_${evaluateContent.code}_agree`
                      ] += 1;
                    }
                  relatedCounties[0].properties = featureProperties;
                  allFeatures.push(relatedCounties[0]);

                  geojsonSource?.setData({
                    type: "FeatureCollection",
                    features: allFeatures,
                  });
                  allFeatures.pop();
                  const musicians: Array<CausesList> = [];
                  JSON.parse(
                    JSON.stringify(featureProperties, null, "  "),
                    (key, value: number) => {
                      if (key) {
                        if (key.indexOf("evalCount_") !== -1) {
                          const Causes: CausesList = {
                            code: key.replace("evalCount_", ""),
                            count: value,
                          };
                          musicians.push(Causes);
                        }
                      }
                    }
                  );
                  setSelectedFeatureProperties(musicians);
                }
              }

              // WebAPIで更新
              const func = async () => {
                // ユーザの評価を更新する
                await putEvaluate?.(
                  Number(layerId),
                  featureId,
                  evaluateContent
                );
                const result = indicatorsRef.current.filter(
                  (indicator) =>
                    indicator.id !== featureId ||
                    indicator.code !==
                      `${evaluateContent.code.toString()}_agree`
                );
                setIndicators(result);
              };
              void func();
            } catch {
              notify({
                type: "error",
                title: "エラー",
                message: "評価の更新に失敗しました",
              });
            }
          }}
        />

        <IconButton
          aria-label="Example"
          sx={{
            position: "fixed",
            right: "-2px",
            bottom: "20px",
          }}
        >
          <Fab color="secondary" aria-label="add" size="small">
            <Checkbox
              icon={
                <img src={`${addicon}`} className="addicon" alt="addicon" />
              }
              checkedIcon={
                <img src={`${addicon}`} className="addicon" alt="addicon" />
              }
              onClick={addMenuDisplayChange}
            />
          </Fab>
        </IconButton>
        <IconButton
          aria-label="Example"
          sx={{
            position: "fixed",
            right: "-2px",
            top: "105px",
          }}
          onClick={MoveToCurrentPosition}
        >
          <Button
            variant="contained"
            color="inherit"
            className="locationButton"
            aria-label="add"
          >
            {lngLat.lat === 34.698738573 && lngLat.lng === 137.386131621 ? (
              <LocationDisabledIcon className="locationDisabledIcon" />
            ) : (
              <MyLocationIcon className="locationDisabledIcon" />
            )}
          </Button>
        </IconButton>
        <IconButton
          aria-label="Example"
          sx={{
            position: "fixed",
            right: "-2px",
            top: "60px",
          }}
          onClick={layerMenuDisplayChange}
        >
          <Button
            variant="contained"
            color="inherit"
            className="layers-icon-button"
            aria-label="add"
          >
            <LayersIcon className="layers-icon" />
          </Button>
        </IconButton>
      </div>
      <canvas
        width="32px"
        height="32px"
        id="canvas"
        className="add-center-icon"
        style={{
          top: height,
          display: `${isHazardousPointRegistrationMenu ? "" : "none"}`,
        }}
      />
      <div
        id="indicator"
        className="indicator"
        style={{
          left: `calc(${window.innerWidth / 2}px - 1.5rem)`,
          top: `calc(${window.innerHeight / 2}px + 0.2rem)`,
          display: `${isIndicator ? "" : "none"}`,
        }}
      >
        <CircularProgress size="3rem" />
      </div>

      {notifications}
    </ThemeProvider>
  );
};

TrafficSafetyUserApp.defaultProps = { userName: "" };

export default TrafficSafetyUserApp;
