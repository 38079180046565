import { useState, useReducer, useCallback, useMemo, useRef } from "react";
import { Snackbar, Alert } from "@mui/material";

type NotificationType = "info" | "success" | "warning" | "error";

type Notification = {
  type?: NotificationType;
  title?: string;
  message?: string;
  uid: string;
};

// TODO: 返値の型が決まったら外す
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useNotifications = () => {
  const [uidCounter, setUidCounter] = useState(0);
  const uidCounterRef = useRef<number>(0);
  uidCounterRef.current = uidCounter;

  const reducer = (
    state: Notification[],
    action: { type: string; notification?: Notification }
  ) => {
    const { type, notification } = action;
    switch (type) {
      case "add": {
        return notification ? state.concat(notification) : state;
      }
      case "remove": {
        return notification
          ? state.filter((n) => n.uid !== notification.uid)
          : state;
      }
      default:
        throw new Error("useNotifications: action type is invalid");
    }
  };

  const [notificationList, dispatch] = useReducer(
    reducer,
    [] as Notification[]
  );

  const notify = useCallback(
    (notification: {
      type?: NotificationType;
      title?: string;
      message?: string;
    }) => {
      dispatch({
        type: "add",
        notification: {
          ...notification,
          uid: uidCounter.toString(),
        },
      });
      setUidCounter(uidCounter + 1);
    },
    [uidCounter]
  );

  const handleToastClose = useCallback((uid: string, reason?: string) => {
    if (reason !== "clickaway" && reason !== "escapeKeyDown") {
      dispatch({
        type: "remove",
        notification: { uid },
      });
    }
  }, []);

  const toastClose = () => {
    dispatch({
      type: "remove",
      notification: { uid: (uidCounterRef.current - 1).toString() },
    });
  };

  const notifications = useMemo(
    () => (
      <>
        {notificationList.map(({ title, type, message, uid }) => (
          <Snackbar
            key={uid}
            open
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{
              top: (document.getElementById("header") as HTMLElement)
                .clientHeight,
              left: "4rem",
              right: "4rem",
            }}
            disableWindowBlurListener
            autoHideDuration={3000}
            onClose={(event?: React.SyntheticEvent | Event, reason?: string) =>
              handleToastClose(uid, reason)
            }
          >
            <Alert severity={type}>{message ?? title ?? ""}</Alert>
          </Snackbar>
        ))}
      </>
    ),
    [handleToastClose, notificationList]
  );

  return [notifications, notify, toastClose] as const;
};

export default useNotifications;
