import { VFC, useState } from "react";
import {
  List,
  ListItem,
  Typography,
  IconButton,
  SwipeableDrawer,
  Grid,
  ListSubheader,
  ListItemText,
  Switch,
} from "@mui/material";
import HdrAutoOutlinedIcon from "@mui/icons-material/HdrAutoOutlined";

import ClearIcon from "@mui/icons-material/Clear";
import { EvaluateContent } from "../hooks/useTrafficSafetyWebAPI";

type LayersViewProps = {
  open: boolean;
  layers: LayerIconList;
  onClose?: (isSideMenu: boolean) => void;
  onUpdate?: (name: string, visible: boolean) => void;
};

export type CausesList = {
  code: string;
  count: number;
};

export type LayerIcon = {
  id: string;
  name: string;
  icon: string;
  visible: boolean;
};

export type LayerIconList = {
  layers: LayerIcon[];
};

export type LocalEvaluate = {
  id: string;
  layerid: string;
  evaluate: EvaluateContent;
  count: number;
};

const LayersView: VFC<LayersViewProps> = function LayersView(
  props: LayersViewProps
) {
  const { open, layers, onClose, onUpdate } = props;

  const handleDrawerClose = () => {
    onClose?.(false);
  };
  const [anchor] = useState<"bottom" | "top" | "right" | "left" | undefined>(
    "bottom"
  );
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const [isCharacterDisplay, setIsCharacterDisplay] = useState<boolean>(true);

  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      onClose?.(false);
    };

  const handleSwitch = (id: string, visible: boolean) => {
    if (id === "名称") setIsCharacterDisplay(!isCharacterDisplay);
    onUpdate?.(id, visible);
  };

  const clearIconStyle = {
    borderRadius: "3px",
    position: "absolute",
    top: "5px",
    left: `0px`,
  };
  const maxMenuHeight = `${
    (document.getElementById("header") as HTMLElement)?.scrollHeight
      ? window.innerHeight -
        (document.getElementById("header") as HTMLElement).scrollHeight -
        250
      : 0
  }px`;

  return (
    <SwipeableDrawer
      variant="persistent"
      anchor={anchor}
      open={open}
      onClose={toggleDrawer()}
      onOpen={toggleDrawer()}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      sx={{
        overflow: "visible",
        WebkitOverflowScrolling: "touch",
        overflowScrolling: "touch",
      }}
    >
      <div
        id="layersView"
        style={{
          maxHeight: maxMenuHeight,
          marginBottom: "10px",
        }}
      >
        <ListSubheader component="div" id="nested-list-subheader">
          <ListItem>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  レイヤの表示
                </Typography>
              </Grid>
            </Grid>

            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerClose}
              sx={clearIconStyle}
            >
              <ClearIcon />
            </IconButton>
          </ListItem>
        </ListSubheader>
        <List>
          {layers.layers.map(({ id, name, icon, visible }) => (
            <div key={id}>
              <ListItem
                sx={{ padding: "2px 16px" }}
                secondaryAction={<div />}
                disablePadding
              >
                <img src={icon} width="25px" loading="lazy" alt="" />
                <ListItemText
                  primaryTypographyProps={{
                    fontWeight: "medium",
                    variant: "body2",
                    marginLeft: "5px",
                  }}
                  primary={name}
                />

                <Switch
                  sx={{
                    right: "10px",
                    position: "absolute",
                  }}
                  checked={visible}
                  onChange={() => handleSwitch(id, visible)}
                />
              </ListItem>
            </div>
          ))}
          <div>
            <ListItem
              sx={{ padding: "2px 16px" }}
              secondaryAction={<div />}
              disablePadding
            >
              <HdrAutoOutlinedIcon sx={{ width: "25px", height: "25px" }} />
              <ListItemText
                primaryTypographyProps={{
                  fontWeight: "medium",
                  variant: "body2",
                  marginLeft: "5px",
                }}
                primary="道路、目標物名称"
              />

              <Switch
                sx={{
                  right: "10px",
                  position: "absolute",
                }}
                checked={isCharacterDisplay}
                onChange={() => handleSwitch("名称", false)}
              />
            </ListItem>
          </div>
        </List>
      </div>
    </SwipeableDrawer>
  );
};

LayersView.defaultProps = {
  onClose: undefined,
  onUpdate: undefined,
};

export default LayersView;
