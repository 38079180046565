import { VFC } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TrafficSafetyUserApp from "./components/TrafficSafetyUserApp";
import { TrafficSafetyAuthProvider } from "./contexts/TrafficSafetyAuthContext";

const App: VFC = function AppContent() {
  return (
    <div className="App">
      <div className="SW-update-dialog" />
      <TrafficSafetyAuthProvider>
        <Router>
          <Routes>
            <Route path="*" element={<TrafficSafetyUserApp />} />
          </Routes>
        </Router>
      </TrafficSafetyAuthProvider>
    </div>
  );
};

export default App;
