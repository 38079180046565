// /src/templates/DashboardTemplate.tsx
import React, { VFC, useCallback } from "react";
// import { Link } from 'react-router-dom';
import {
  Box,
  Divider,
  SwipeableDrawer,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  ListItemButton,
  Typography,
  Fab,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import LayersIcon from "@mui/icons-material/Layers";
import FeedIcon from "@mui/icons-material/Feed";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import { AppVersion } from "../Version";
import addicon from "../images/addicon.svg";
import gikadai from "../images/gikadai_logo.gif";
import toyohashishi from "../images/toyohashishi.svg";

export type LayerList = {
  label: string;
  id: string;
};

type SideMenuComponentProps = {
  isSideMenu: boolean;
  onConfirm?: (sideMenu: boolean) => void;
  onPositionCurrent?: (sideMenu: boolean) => void;
  onRegistration?: (sideMenu: boolean) => void;
  onMapTextView?: (sideMenu: boolean) => void;
  onTermsOfUseDialog?: (sideMenu: boolean) => void;
};

const SideMenuComponent: VFC<SideMenuComponentProps> =
  function SideMenuComponentContent(props: SideMenuComponentProps) {
    const {
      isSideMenu,
      onConfirm,
      onPositionCurrent,
      onRegistration,
      onMapTextView,
      onTermsOfUseDialog,
    } = props;

    const anchor = "left";
    const iOS =
      typeof navigator !== "undefined" &&
      /iPad|iPhone|iPod/.test(navigator.userAgent);

    const Confirm = useCallback(() => {
      onConfirm?.(false);
    }, [onConfirm]);

    const MoveHome = useCallback(() => {
      onConfirm?.(false);
    }, [onConfirm]);

    const MapTextView = useCallback(() => {
      onMapTextView?.(false);
    }, [onMapTextView]);

    const DisplayPositionCurrent = useCallback(() => {
      onPositionCurrent?.(false);
    }, [onPositionCurrent]);

    const RegisterHazardousArea = useCallback(() => {
      onRegistration?.(false);
    }, [onRegistration]);

    const TermsOfUseDialog = useCallback(() => {
      onTermsOfUseDialog?.(false);
    }, [onTermsOfUseDialog]);

    const data = [
      {
        icon: <ReportProblemIcon />,
        label: "危険地域を表示する",
        action: MoveHome,
      },
      {
        icon: (
          <Fab
            color="secondary"
            aria-label="add"
            size="small"
            sx={{ width: "24px", minHeight: "24px", height: "24px" }}
          >
            <img
              src={`${addicon}`}
              style={{ width: "16px", height: "16px" }}
              alt="addicon"
            />
          </Fab>
        ),
        label: "危険地域を登録する",
        action: RegisterHazardousArea,
      },
      {
        icon: <LayersIcon />,
        label: "レイヤ表示を切り替える",
        action: MapTextView,
      },
      {
        icon: <MyLocationOutlinedIcon />,
        label: "現在位置を表示する",
        action: DisplayPositionCurrent,
      },
    ];

    const affiliated = [
      {
        image: <img src={toyohashishi} alt="豊橋市" height="30px" />,
        label: "豊橋市",
      },
      {
        image: <img src={gikadai} alt="豊橋技術科学大学" height="30px" />,
        label: "国立大学法人 豊橋技術科学大学",
      },
    ];
    const toggleDrawer =
      (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event &&
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        onConfirm?.(open);
      };

    const versionStyle = {
      fontSize: 12,
      color: "#a9a9a9",
    };

    const toyohashiStyle = {
      fontSize: 20,

      fontWeight: "bold",
      typography: "subtitle2",
    };

    return (
      <SwipeableDrawer
        key="SideMenu"
        anchor={anchor}
        open={isSideMenu}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableBackdropTransition={!iOS}
        disableDiscovery={!iOS}
        disableSwipeToOpen={typeof navigator !== "undefined"}
      >
        <List sx={{ width: "100vw", height: "100vh" }} key="side-menu">
          <IconButton color="inherit" aria-label="menu" onClick={Confirm}>
            <ChevronLeftIcon />
          </IconButton>
          <Divider />
          {affiliated.map((item) => (
            <ListItem key={item.label}>
              <ListItemIcon sx={{ maxWidth: "30px" }}>
                {item.image}
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body1" style={toyohashiStyle}>
                    {item.label}
                  </Typography>
                }
              />
            </ListItem>
          ))}
          <Divider />
          {data.map((item) => (
            <ListItemButton onClick={item.action} sx={{ color: "inherit" }}>
              <ListItemIcon sx={{ color: "inherit", minWidth: "30px" }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          ))}
          <Divider />
          <ListItemButton onClick={TermsOfUseDialog} sx={{ color: "inherit" }}>
            <ListItemIcon sx={{ color: "inherit", minWidth: "30px" }}>
              <FeedIcon />
            </ListItemIcon>
            <ListItemText primary="利用規約" />
          </ListItemButton>
          <Divider />
          <ListItem sx={versionStyle}>バージョン：{AppVersion}</ListItem>
        </List>
        <Box display="flex" justifyContent="center" sx={{ p: 2 }}>
          <Link
            href="https://www.mapquest.co.jp/"
            underline="none"
            target="_blank"
            rel="noopener"
          >
            Powered by MapQuest
          </Link>
        </Box>
      </SwipeableDrawer>
    );
  };

SideMenuComponent.defaultProps = {
  onConfirm: undefined,
  onPositionCurrent: undefined,
  onRegistration: undefined,
  onTermsOfUseDialog: undefined,
  onMapTextView: undefined,
};

export default SideMenuComponent;
