import { useMemo } from "react";

export type GeometryType = "point" | "lineString" | "polygon";
export const layerStyleTypes = ["line", "circle", "fill"] as const;
export type LayerStyleType = typeof layerStyleTypes[number];

export type LayerScheme = {
  key: string;
  type: "string" | "number" | "boolean" | null;
  defaultValue: string | number | boolean | null;
}[];

export type EvaluateContent = {
  guid: string;
  code: number;
  evaluate: string;
};

export type MapContent = {
  id?: number;
  name: string;
  zoom?: number;
  min_zoom?: number;
  max_zoom?: number;
  center?: [number, number];
  _revision?: number;
};

export type EvaluationItem = {
  code: number;
  title: string;
};

export type LayerContent = {
  evaluationItems: EvaluationItem[];
  id: number;
  isEditable: boolean;
  isVisible: boolean;
  evaluationIsExclusive: boolean;
  name: string;
  document_type: string;
  propertySchemas: PropertySchemas[];
};

export type PropertySchemas = {
  key: string;
  name: string;
  type: string;
};

export type layersContent = {
  layers: LayerContent[];
};

export type feature = {
  featureId: string;
  evalItems: { [key: string]: unknown };
};

export type layer = {
  layerId: number;
  features: feature[];
};

export type EvaluationsContent = {
  layers: layer[];
};

export type CreateFeatureContent = {
  type: string;
  geometry: {
    type: string;
    coordinates: [number, number];
  };
  properties?: {
    id: string;
  };
  id?: string;
};

const getHttp = async <T>(url: string): Promise<T> => {
  try {
    const response = await fetch(url, {
      method: "GET",
    });

    const body = await response.text();
    if (response.ok) {
      const result = (body.length ? JSON.parse(body) : null) as T;
      return result;
    }
    return Promise.reject(new Error(body));
  } catch (e: unknown) {
    return Promise.reject(e);
  }
};

const postHttp = async <T>(url: string, data: unknown): Promise<T> => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },

      body: JSON.stringify(data),
    });
    const body = await response.text();
    if (response.ok) {
      const result = (body.length ? JSON.parse(body) : null) as T;
      return result;
    }
    return Promise.reject(new Error(body));
  } catch (e: unknown) {
    return Promise.reject(e);
  }
};

const putHttp = async <T>(url: string, data: unknown): Promise<T> => {
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const body = await response.text();
    if (response.ok) {
      const result = (body.length ? JSON.parse(body) : null) as T;
      return result;
    }
    return Promise.reject(new Error(body));
  } catch (e: unknown) {
    return Promise.reject(e);
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTrafficSafetyWebAPI = (
  mqplatformDomain: string | null | undefined
) => {
  const apis = useMemo(() => {
    if (!mqplatformDomain) {
      return {};
    }

    const getLayers = () =>
      getHttp<layersContent>(`${mqplatformDomain}/api/layers`);

    const getFeatures = (layerId: string | number) =>
      getHttp<LayerContent[]>(
        `${mqplatformDomain}/api/layers/${layerId}/features`
      );

    const getFeaturesCollection = (layerId: string | number) =>
      getHttp<GeoJSON.FeatureCollection>(
        `${mqplatformDomain}/api/layers/${layerId}/features`
      );

    const putEvaluate = (
      layerId: number,
      featureId: string,
      evaluateContent: EvaluateContent
    ) =>
      putHttp<null>(
        `${mqplatformDomain}/api/layers/${layerId}/features/${featureId}/evaluate`,
        evaluateContent
      );

    const getEvaluations = (guid: string) =>
      getHttp<EvaluationsContent>(
        `${mqplatformDomain}/api/evaluations/${guid}`
      );

    const createFeature = (layerId: number, data: CreateFeatureContent) =>
      postHttp<CreateFeatureContent>(
        `${mqplatformDomain}/api/layers/${layerId}/features`,
        data
      );

    return {
      getLayers,
      getFeatures,
      putEvaluate,
      getEvaluations,
      createFeature,
      getFeaturesCollection,
    } as const;
  }, [mqplatformDomain]);

  return [
    {},
    {
      ...apis,
    },
  ] as const;
};
export default useTrafficSafetyWebAPI;
