import { VFC, useState, useEffect, ChangeEvent } from "react";
import {
  Button,
  Drawer,
  List,
  ListItem,
  Box,
  Checkbox,
  IconButton,
  ListItemText,
  Typography,
  ListSubheader,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { EvaluationItem } from "../hooks/useTrafficSafetyWebAPI";

type HazardousPointRegistrationMenuProps = {
  open: boolean;
  dangerReason: EvaluationItem[];
  onClose?: (isSideMenu: boolean) => void;
  onCreate?: (isSideMenu: boolean, causes: number[]) => void;
};

const HazardousPointRegistrationMenu: VFC<HazardousPointRegistrationMenuProps> =
  function HazardousPointRegistrationMenuContent(
    props: HazardousPointRegistrationMenuProps
  ) {
    const { open, dangerReason, onClose, onCreate } = props;
    const [causes, setCauses] = useState<number[]>([]);
    const [checked, setChecked] = useState<number[]>([]);

    const handleDrawerClose = () => {
      setCauses([]);
      onClose?.(false);
    };

    const handleRegister = () => {
      onCreate?.(false, causes);
    };

    const [anchor] = useState<"bottom" | "top" | "right" | "left" | undefined>(
      "bottom"
    );

    useEffect(() => {
      // オープン時に初期化
      if (open) setChecked([]);
      setCauses([]);
    }, [open]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      // イベントが存在するか
      if (event) {
        if (event.target.checked) {
          setCauses([...causes, Number(event.target.id)]);
        } else {
          setCauses(
            causes.filter((cause) => cause !== Number(event.target.id))
          );
        }
      }
    };

    const handleToggle = (value: number) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      if (newChecked) {
        setCauses([...causes, value]);
      } else {
        setCauses(causes.filter((cause) => cause !== value));
      }

      setChecked(newChecked);
    };
    const clearIconStyle = {
      borderRadius: "3px",
      position: "absolute",
      top: "5px",
      left: `0px`,
    };

    const addButtonStyle = {
      borderRadius: "30px",
      position: "absolute",
      top: "5px",
      right: `0px`,
    };

    const maxMenuHeight = `${
      (document.getElementById("header") as HTMLElement)?.scrollHeight
        ? window.innerHeight -
          (document.getElementById("header") as HTMLElement).scrollHeight -
          250
        : 0
    }px`;

    return (
      <Box sx={{ display: "flex", overscrollBehaviorY: "none" }}>
        <Drawer variant="persistent" anchor={anchor} open={open}>
          <div
            id="HazardousPointRegistration"
            style={{ maxHeight: maxMenuHeight, marginBottom: "10px" }}
          >
            <ListSubheader component="div" id="nested-list-subheader">
              <ListItem>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleDrawerClose}
                  sx={clearIconStyle}
                >
                  <ClearIcon />
                </IconButton>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRegister}
                  disabled={checked.length === 0}
                  sx={addButtonStyle}
                >
                  登録する
                </Button>

                <Typography
                  variant="h6"
                  noWrap
                  sx={{
                    paddingLeft: 1,
                    paddingRight: 1,
                    marginLeft: "auto",
                    marginRight: "auto",

                    textAlign: "center",
                  }}
                >
                  危険地点を登録
                </Typography>
              </ListItem>
            </ListSubheader>
            <div id="HazardousPointRegistrationMenu">
              <List>
                {dangerReason.map(({ title, code }) => (
                  <ListItem
                    sx={{
                      display: code === -1 ? "none" : "",
                      padding: "2px 16px",
                    }}
                    secondaryAction={
                      <Checkbox
                        id={code.toString()}
                        edge="end"
                        onClick={handleToggle(Number(code))}
                        onChange={handleChange}
                        icon={
                          <Button
                            size="small"
                            sx={{ width: "5rem" }}
                            variant="outlined"
                          >
                            そう思う
                          </Button>
                        }
                        checkedIcon={
                          <Button
                            size="small"
                            sx={{ width: "5rem" }}
                            variant="contained"
                          >
                            そう思う
                          </Button>
                        }
                        checked={checked.indexOf(code) !== -1}
                      />
                    }
                    disablePadding
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: "medium",
                        variant: "body2",
                      }}
                      primary={title}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        </Drawer>
      </Box>
    );
  };

HazardousPointRegistrationMenu.defaultProps = {
  onClose: undefined,
  onCreate: undefined,
};

export default HazardousPointRegistrationMenu;
