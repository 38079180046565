import { VFC, useState, useEffect } from "react";
import {
  List,
  ListItem,
  Checkbox,
  Typography,
  IconButton,
  SwipeableDrawer,
  ListItemText,
  Badge,
  Grid,
  styled,
  CircularProgress,
  ListSubheader,
  Button,
  Divider,
} from "@mui/material";

import ClearIcon from "@mui/icons-material/Clear";
import useTrafficSafetyWebAPI, {
  EvaluateContent,
  EvaluationItem,
  EvaluationsContent,
} from "../hooks/useTrafficSafetyWebAPI";
import { useAuth } from "../contexts/TrafficSafetyAuthContext";
import { Indicator } from "./TrafficSafetyUserApp";

type DetailedViewProps = {
  open: boolean;
  featureProperties: CausesList[];
  evaluationItems: EvaluationItem[];
  selectedFeatureId: string;
  selectedLayerId: string;
  evaluation: EvaluationsContent;
  indicators: Indicator[];
  isExclusive: boolean;
  onClose?: (isSideMenu: boolean) => void;
  onConfirm?: (featureId: string, code: string, isThink: boolean) => void;
  onDisable?: (
    layerId: number,
    featureId: string,
    checkedList: number[],
    evaluateContent: EvaluateContent
  ) => void;
  onUpdate?: (
    layerId: number,
    featureId: string,
    evaluateContent: EvaluateContent,
    value: number,
    code: string,
    isExclusive: boolean,
    counterCheck: boolean,
    oppositionCode: string,
    checkedList: number[]
  ) => void;
};

export type CausesList = {
  code: string;
  count: number;
};

export type LocalEvaluate = {
  id: string;
  layerid: string;
  evaluate: EvaluateContent;
  count: number;
};

const DetailedView: VFC<DetailedViewProps> = function DetailedView(
  props: DetailedViewProps
) {
  const {
    open,
    featureProperties,
    evaluationItems,
    selectedFeatureId,
    selectedLayerId,
    evaluation,
    indicators,
    isExclusive,
    onClose,
    onConfirm,
    onUpdate,
    onDisable,
  } = props;

  const [{ domain, uuid }] = useAuth();
  const [, { getEvaluations }] = useTrafficSafetyWebAPI(domain);

  const handleDrawerClose = () => {
    onClose?.(false);
  };
  const [anchor] = useState<"bottom" | "top" | "right" | "left" | undefined>(
    "bottom"
  );
  const [localIndicators, setLocalIndicators] = useState<Indicator[]>([]);
  const [localEvaluates, setLocalEvaluates] = useState<LocalEvaluate[]>([]);
  const [agreeChecked, setAgreeChecked] = useState<number[]>([]);
  const [disagreeChecked, setDisagreeChecked] = useState<number[]>([]);
  const [agreeCodes, setAgreeCodes] = useState<number[]>([]);
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  useEffect(() => {
    setLocalIndicators(
      indicators.filter((indicator) => indicator.isThink === true)
    );
  }, [indicators]);

  useEffect(() => {
    if (featureProperties.length && selectedLayerId && selectedFeatureId) {
      const func = () => {
        if (uuid) {
          // 選択したFeatureがサーバに持つEvalItemsを取得
          const evalItemList = evaluation?.layers
            .find((x) => x.layerId === Number(selectedLayerId))
            ?.features.find(
              (x) => x.featureId === selectedFeatureId
            )?.evalItems;

          const agree = Object.fromEntries(
            Object.entries(evalItemList ?? {}).filter(
              ([, value]) => value === 1
            )
          );
          const agreeCodeList = Object.keys(agree).map(Number);
          setAgreeCodes(agreeCodeList);

          // ローカルで選択した項目を取得
          const deleteItems = localEvaluates.filter(
            (x) => x.id === selectedFeatureId && x.layerid === selectedLayerId
          );

          if (agreeCodeList === undefined || deleteItems.length !== 0) {
            // ローカルで選択した項目を3種類に分ける
            const noneList = deleteItems.map((x) =>
              x.evaluate.evaluate === "none" ? Number(x.evaluate.code) : NaN
            );
            const agreeList = deleteItems.map((x) =>
              x.evaluate.evaluate === "agree" ? Number(x.evaluate.code) : NaN
            );
            const disagreeList = deleteItems.map((x) =>
              x.evaluate.evaluate === "disagree" ? Number(x.evaluate.code) : NaN
            );

            const agreeChecks = agreeList.filter(
              (i) => noneList.indexOf(i) === -1
            );
            const disagreeChecks = disagreeList.filter(
              (i) => noneList.indexOf(i) === -1
            );

            setAgreeChecked(agreeChecks);
            setDisagreeChecked(disagreeChecks);
          }
          if (agreeCodeList || deleteItems.length) {
            const falseList = deleteItems.map((x) =>
              x.evaluate.evaluate === "disagree" ||
              x.evaluate.evaluate === "none"
                ? Number(x.evaluate.code)
                : NaN
            );
            const agreeList = deleteItems.map((x) =>
              x.evaluate.evaluate === "agree" ? Number(x.evaluate.code) : NaN
            );
            // サーバ、ローカルで重複する場合はボタンをOFF
            // サーバ、ローカルのどちらかに存在する場合はボタンをON
            const agreeValues = Array.from(
              new Set([...agreeCodeList, ...agreeList])
            );
            const agreeChecks = agreeValues.filter(
              (i) => falseList.indexOf(i) === -1
            );

            setAgreeChecked(
              deleteItems.length !== 0 ? agreeChecks : agreeCodeList
            );
          }
        }
      };
      void func();
    }
  }, [
    evaluation,
    featureProperties,
    getEvaluations,
    localEvaluates,
    selectedFeatureId,
    selectedLayerId,
    uuid,
  ]);

  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      onClose?.(false);
    };

  const handleChange = (id: number, checked: boolean) => {
    // 更新用のコンテンツを作成
    if (uuid) {
      const evaluateContent: EvaluateContent = {
        guid: uuid,
        code: id,
        evaluate: checked ? "agree" : "none",
      };
      setLocalIndicators([
        ...localIndicators,
        {
          id: selectedFeatureId,
          code: `${id}`,
          isThink: true,
        },
      ]);

      // 評価を取り消す項目と「特に危険はないと思う」ボタンをOFFにする
      localEvaluates
        .filter(
          (x) =>
            x.id === selectedFeatureId &&
            x.layerid === selectedLayerId &&
            (x.evaluate.code === evaluateContent.code || (x.evaluate.code <= -1 && isExclusive))
        )
        .forEach((temporarydata) => {
          const temporarydataIndex = localEvaluates.indexOf(temporarydata);
          localEvaluates.splice(temporarydataIndex, 1);
        });

      if (agreeCodes.find((x) => x <= -1) && isExclusive) {
        setLocalEvaluates([
          ...localEvaluates,
          {
            id: selectedFeatureId,
            layerid: selectedLayerId,
            evaluate: evaluateContent,
            count: evaluateContent.evaluate === "agree" ? 1 : 0,
          },
          {
            id: selectedFeatureId,
            layerid: selectedLayerId,
            evaluate: {
              guid: uuid,
              code: -1,
              evaluate: "none",
            },
            count: 0,
          },
        ]);
      } else {
        setLocalEvaluates([
          ...localEvaluates,
          {
            id: selectedFeatureId,
            layerid: selectedLayerId,
            evaluate: evaluateContent,
            count: evaluateContent.evaluate === "agree" ? 1 : 0,
          },
        ]);
      }

      onUpdate?.(
        Number(selectedLayerId),
        selectedFeatureId,
        evaluateContent,
        evaluateContent.evaluate === "agree" ? 1 : -1,
        "agree",
        isExclusive,
        disagreeChecked.includes(evaluateContent.code),
        "disagree",
        agreeChecked
      );
    }
  };

  const handleToggle = (code: number) => {
    if (
      localIndicators.filter(
        (indicator) =>
          indicator.id === selectedFeatureId &&
          (indicator.code === `${code.toString()}_agree` ||
            indicator.code === `${code.toString()}_disagree` ||
            indicator.code === `-${code.toString()}_agree`)
      ).length !== 0
    )
      return;
    if (code <= -1 && isExclusive) {
      if (uuid) {
        if (
          localIndicators.filter(
            (indicator) => indicator.id === selectedFeatureId
          ).length !== 0
        )
          return;
        const currentIndex = agreeChecked.indexOf(code);
        const evaluateContent: EvaluateContent = {
          guid: uuid,
          code,
          evaluate: currentIndex === -1 ? "agree" : "none",
        };

        // 全て消す
        localEvaluates
          .filter(
            (x) => x.id === selectedFeatureId && x.layerid === selectedLayerId
          )
          .forEach((temporarydata) => {
            if (temporarydata) {
              const temporarydataIndex = localEvaluates.indexOf(temporarydata);
              localEvaluates.splice(temporarydataIndex, 1);
            }
          });
        const addLocalEvaluates = localEvaluates.filter(
          (x) => x.layerid === selectedLayerId && x.id !== selectedFeatureId
        );
        addLocalEvaluates.push({
          id: selectedFeatureId,
          layerid: selectedLayerId,
          evaluate: evaluateContent,
          count: evaluateContent.evaluate === "agree" ? 1 : 0,
        });
        // 既存のCheckをOFFにする
        agreeCodes.forEach((x) => {
          addLocalEvaluates.push({
            id: selectedFeatureId,
            layerid: selectedLayerId,
            evaluate: {
              guid: uuid,
              code: x,
              evaluate: "none",
            },
            count: 0,
          });
        });

        onConfirm?.(selectedFeatureId, `${code.toString()}_agree`, true);

        setLocalEvaluates(addLocalEvaluates);
        onDisable?.(
          Number(selectedLayerId),
          selectedFeatureId,
          agreeChecked,
          evaluateContent
        );
        setAgreeChecked([]);
      }
    } else {
      const currentIndex = agreeChecked.indexOf(code);
      const newChecked = [...agreeChecked];
      if (currentIndex === -1) {
        newChecked.push(code);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      onConfirm?.(selectedFeatureId, `${code.toString()}_agree`, true);
      const func = () => {
        if (currentIndex === -1) {
          handleChange(code, true);
        } else {
          handleChange(code, false);
        }
      };
      void func();

      const index = agreeChecked.indexOf(-1);
      if (index !== -1) {
        newChecked.splice(index, 1);
      }

      setAgreeChecked(newChecked);
    }
  };

  const StyledBadge = styled(Badge)(() => ({
    "& .MuiBadge-badge": {
      right: -25,
      top: 15,

      color: "#000",
      backgroundColor: "transparent",
    },
  }));
  const clearIconStyle = {
    borderRadius: "3px",
    position: "absolute",
    top: "5px",
    left: `0px`,
  };
  const maxMenuHeight = `${
    (document.getElementById("header") as HTMLElement)?.scrollHeight
      ? window.innerHeight -
        (document.getElementById("header") as HTMLElement).scrollHeight -
        250
      : 0
  }px`;

  return (
    <SwipeableDrawer
      variant="persistent"
      anchor={anchor}
      open={open}
      onClose={toggleDrawer()}
      onOpen={toggleDrawer()}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      sx={{
        overflow: "visible",
        WebkitOverflowScrolling: "touch",
        overflowScrolling: "touch",
      }}
    >
      <div
        id="detailedView"
        style={{
          maxHeight: maxMenuHeight,
          marginBottom: "10px",
        }}
      >
        <ListSubheader component="div" id="nested-list-subheader">
          <ListItem>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  みんなの意見
                </Typography>
              </Grid>
            </Grid>

            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerClose}
              sx={clearIconStyle}
            >
              <ClearIcon />
            </IconButton>
          </ListItem>
        </ListSubheader>
        <List>
          {evaluationItems?.map(({ title, code }) => (
            <div>
              <ListItem
                sx={{ padding: "2px 16px" }}
                secondaryAction={
                  <div>
                    <Checkbox
                      sx={{ right: "50px" }} // 50px
                      onClick={() => handleToggle(Number(code))}
                      id={`${code.toString()}_agree`}
                      edge="end"
                      icon={
                        <StyledBadge
                          max={9999}
                          badgeContent={
                            featureProperties.some(
                              (x) => x.code === `${code.toString()}_agree`
                            )
                              ? `${
                                  (featureProperties.find(
                                    (x) => x.code === `${code.toString()}_agree`
                                  )?.count as number) > 9999
                                    ? `${(
                                        (featureProperties.find(
                                          (x) =>
                                            x.code ===
                                            `${code.toString()}_agree`
                                        )?.count as number) / 10000
                                      ).toFixed(1)}万`
                                    : (featureProperties.find(
                                        (x) =>
                                          x.code === `${code.toString()}_agree`
                                      )?.count as number)
                                }`
                              : "0"
                          }
                          color="primary"
                        >
                          {localIndicators.filter(
                            (indicator) =>
                              indicator.id === selectedFeatureId &&
                              indicator.code === `${code.toString()}_agree`
                          ).length !== 0 ? (
                            <Button
                              size="small"
                              sx={{ width: "5rem" }} // 80px
                              variant="outlined"
                            >
                              <CircularProgress color="secondary" size={25} />
                            </Button>
                          ) : (
                            <Button
                              size="small"
                              sx={{ width: "5rem" }}
                              variant="outlined"
                            >
                              そう思う
                            </Button>
                          )}
                        </StyledBadge>
                      }
                      checkedIcon={
                        <StyledBadge
                          max={9999}
                          badgeContent={
                            featureProperties.some(
                              (x) => x.code === `${code.toString()}_agree`
                            )
                              ? `${
                                  (featureProperties.find(
                                    (x) => x.code === `${code.toString()}_agree`
                                  )?.count as number) > 10000
                                    ? `${(
                                        (featureProperties.find(
                                          (x) =>
                                            x.code ===
                                            `${code.toString()}_agree`
                                        )?.count as number) / 10000
                                      ).toFixed(1)}万`
                                    : (featureProperties.find(
                                        (x) =>
                                          x.code === `${code.toString()}_agree`
                                      )?.count as number)
                                }`
                              : "0"
                          }
                          color="primary"
                        >
                          {localIndicators.filter(
                            (indicator) =>
                              indicator.id === selectedFeatureId &&
                              indicator.code === `${code.toString()}_agree`
                          ).length !== 0 ? (
                            <Button
                              size="small"
                              sx={{ width: "5rem" }}
                              variant="contained"
                            >
                              <CircularProgress color="secondary" size={25} />
                            </Button>
                          ) : (
                            <Button
                              size="small"
                              sx={{ width: "5rem" }}
                              variant="contained"
                            >
                              そう思う
                            </Button>
                          )}
                        </StyledBadge>
                      }
                      checked={agreeChecked.indexOf(Number(code)) !== -1}
                    />
                  </div>
                }
                disablePadding
              >
                <ListItemText
                  primaryTypographyProps={{
                    fontWeight: "medium",
                    variant: "body2",
                  }}
                  primary={title}
                />
              </ListItem>
              {evaluationItems?.filter((x) => x.code <= code).length === 1 ? (
                <Divider
                  variant="middle"
                  sx={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
                />
              ) : (
                ""
              )}
            </div>
          ))}
        </List>
      </div>
    </SwipeableDrawer>
  );
};

DetailedView.defaultProps = {
  onClose: undefined,
  onConfirm: undefined,
  onUpdate: undefined,
  onDisable: undefined,
};

export default DetailedView;
