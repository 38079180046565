import { TransformRequestFunction } from "mapbox-gl";

export function TrafficSafetyTransformRequest(): TransformRequestFunction {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (url: string, resourceType: string) => {
    if (url.startsWith("http://localhost:3000")) {
      return {
        // TODO: API Managementを介する？
        url: `${url.replace("http://localhost:3000", "http://localhost:7071")}`,
      };
    }
    return { url };
  };
}
