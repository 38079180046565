// /src/templates/DashboardTemplate.tsx
import React, { VFC, useCallback } from "react";
// import { Link } from 'react-router-dom';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Modal,
  Box,
  Typography,
  ListSubheader,
  Grid,
  Container,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import mainView from "../images/main_view.svg";
import userHazardouspointIcon from "../images/userHazardouspoints.png";
import image2 from "../images/image2.png";
import image3 from "../images/image3.svg";
import image5 from "../images/image5.svg";
import sidemenu from "../images/sidemenu.svg";
import helpIcon from "../images/help.svg";
import gpsIcon from "../images/gps.svg";
import layerIcon from "../images/label.svg";
import hazardMarker from "../images/hazardmarker.png";
import mylocation from "../images/mylocation.png";
import agreeButton from "../images/agree.png";
import disagreeButton from "../images/disagree.png";
import schoolRouteHazardousIcon from "../images/schoolRouteHazardousIcon.png";
import stopSignalIcon from "../images/stopSignalIcon.svg";
import zone30s from "../images/zone30s.png";
import bicycleLanes from "../images/bicycleLanes.png";
import crosswalkIcon from "../images/crosswalk.png";
import LayerView from "../images/layerView.png";
import addIcon from "../images/add.svg";
import iconImage from "../images/iconImage.png";
import "../Help.css";

type HelpDialogProps = {
  isSideMenu: boolean;
  onClose?: (sideMenu: boolean) => void;
  onShowHelp?: () => void;
};

const HelpDialog: VFC<HelpDialogProps> = function HelpDialog(
  props: HelpDialogProps
) {
  const { isSideMenu, onClose, onShowHelp } = props;

  const Close = useCallback(() => {
    onClose?.(false);
  }, [onClose]);

  const ShowHelp = useCallback(() => {
    onShowHelp?.();
  }, [onShowHelp]);

  const style = {
    position: "absolute",
    top: "5%",
    left: "5%",
    width: "90%",
    height: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    overflow: "scroll",
  };

  const headText = {
    pl: 2,
    borderLeft: "7px solid #707070",
  };
  const bodyText = {
    fontSize: 13,
    pl: 4,
    marginBottom: "10px",
    marginRight: "30px",
  };
  const yukkuriText = {
    fontSize: 16,
    pl: 4,
    textAlign: "center",
  };
  const titleText = {
    boxSizing: "border-box",
    lineHeight: "48px",
    listStyle: "none",
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    fontWeight: 500,
    paddingLeft: "16px",

    top: 0,
    zIndex: 1,
    backgroundColor: "#fff",
    fontSize: "1.5em",
  };

  const listSubheader = { fontSize: "1.5em" };

  const clearIconStyle = {
    borderRadius: "3px",
    position: "absolute",
    top: "5px",
    left: `0px`,
  };

  const TermsIconStyle = {
    borderRadius: "3px",
    position: "absolute",
    fontSize: 13,
    top: "5px",
    right: `0px`,
  };

  return (
    <Modal
      open={isSideMenu}
      onClose={Close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} id="box">
        <List
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              sx={listSubheader}
            >
              <ListItem>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      ヘルプ
                    </Typography>
                  </Grid>
                </Grid>

                <IconButton
                  color="inherit"
                  aria-label="menu"
                  onClick={Close}
                  sx={clearIconStyle}
                >
                  <ClearIcon />
                </IconButton>
                <IconButton
                  color="inherit"
                  aria-label="TermsIcon"
                  onClick={ShowHelp}
                  sx={TermsIconStyle}
                >
                  利用規約
                </IconButton>
              </ListItem>
            </ListSubheader>
          }
        >
          <Divider />
          <div style={{ textAlign: "center", marginTop: "1.0rem" }}>
            <img src={iconImage} width="50%" loading="lazy" alt="ゆっくり君" />
          </div>
          <Container maxWidth="xs" sx={yukkuriText}>
            豊橋交通安全アプリへようこそ！！
          </Container>
          <Divider sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }} />
          <Box sx={titleText}>豊橋交通安全アプリとは？</Box>
          <Typography sx={bodyText}>
            豊橋市民のみなさんの安心・安全な生活を目指し、市内の車両通行データを収集・分析し、交通事故の危険がある場所（危険地点）をマップで案内します。身の回りの安全確認に活用してください。
          </Typography>
          <Typography sx={bodyText}>
            また、危険地点の実態について意見を集める機能を用意しています。
            集まった情報は、今後の道路整備・改良などの参考にさせていただきますので、情報提供についてご協力お願いします。
          </Typography>
          <Divider />
          <List
            sx={{ width: "100%", bgcolor: "background.paper" }}
            subheader={<Box sx={titleText}>画面説明</Box>}
          >
            <Typography sx={bodyText}>
              メイン画面の主な機能について説明します。
              <br />
            </Typography>
            <Typography sx={bodyText}>
              <img src={mainView} width="300px" loading="lazy" alt="a" />
            </Typography>
            <Typography sx={bodyText}>
              <table>
                <tr>
                  <td className="image">
                    <img
                      src={sidemenu}
                      width="30px"
                      loading="lazy"
                      alt="サイドメニューボタン"
                    />
                  </td>
                  <td>
                    <h4 className="buttonTitle">1. サイドメニューボタン</h4>
                    サイドメニューが表示されます。
                  </td>
                </tr>
                <tr>
                  <td className="image">
                    <img
                      src={helpIcon}
                      width="30px"
                      loading="lazy"
                      alt="ヘルプボタン"
                    />
                  </td>
                  <td>
                    <h4 className="buttonTitle">2. ヘルプボタン</h4>
                    ヘルプが表示されます。
                  </td>
                </tr>
                <tr>
                  <td className="image">
                    <img
                      src={layerIcon}
                      width="30px"
                      loading="lazy"
                      alt="LayerIcon"
                    />
                  </td>
                  <td>
                    <h4 className="buttonTitle">3. レイヤの表示ボタン</h4>
                    地図上に表示されるアイコンや図形が何を示しているかを説明します。
                    各種アイコンや図形の表示/非表示を切え替えられます。
                  </td>
                </tr>
                <tr>
                  <td className="image">
                    <img
                      src={gpsIcon}
                      width="30px"
                      loading="lazy"
                      alt="GPSIcon"
                    />
                  </td>
                  <td>
                    <h4 className="buttonTitle">4. 現在地表示ボタン</h4>
                    GPSで現在地が取得され、地図の中心に表示されます。
                  </td>
                </tr>
                <tr>
                  <td className="image">
                    <img
                      src={addIcon}
                      width="30px"
                      loading="lazy"
                      alt="GPSIcon"
                    />
                  </td>
                  <td>
                    <h4 className="buttonTitle">5. 危険地点追加ボタン</h4>
                    危険地点が新しく登録されます。
                    登録されていない危険地点がありましたら、
                    こちらのボタンから危険地点の登録をお願いします。
                  </td>
                </tr>
              </table>
            </Typography>
          </List>
          <Divider />
          <List
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={<Box sx={titleText}>マーカーについて</Box>}
          >
            <Typography sx={bodyText}>
              地図上に表示される各種マーカーについて説明します。
            </Typography>
            <Typography sx={bodyText}>
              <table>
                <tr>
                  <td className="image">
                    <img
                      src={hazardMarker}
                      width="30px"
                      height="30px"
                      loading="lazy"
                      alt="危険地点"
                    />
                  </td>
                  <td>
                    車両通行データ等から得た危険地点です。
                    <br />
                    危険度が高いほど濃い色で表現されます。
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={userHazardouspointIcon}
                      width="30px"
                      loading="lazy"
                      alt="利用者提供危険地点"
                    />
                  </td>
                  <td>みなさんから報告された危険地点です。</td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={schoolRouteHazardousIcon}
                      width="30px"
                      height="30px"
                      loading="lazy"
                      alt="通学路危険地点"
                    />
                  </td>
                  <td>
                    主に通学路上で特に小学生事故に注意が必要な場所です。
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={stopSignalIcon}
                      width="30px"
                      height="30px"
                      loading="lazy"
                      alt="一時停止"
                    />
                  </td>
                  <td>
                    一時停止義務がある交差点のうち、特に注意が必要な場所です。
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={bicycleLanes}
                      width="30px"
                      height="30px"
                      loading="lazy"
                      alt="自転車通行帯"
                    />
                  </td>
                  <td>
                    自転車通行帯が設置されている区間です。
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={zone30s}
                      width="30px"
                      height="30px"
                      loading="lazy"
                      alt="ゾーン30"
                    />
                  </td>
                  <td>
                    最高速度「時速30㎞」の速度規制を実施された生活道路を含む区域です。
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={crosswalkIcon}
                      width="30px"
                      height="30px"
                      loading="lazy"
                      alt="横断歩道"
                    />
                  </td>
                  <td>
                    横断歩道のうち、特に注意が必要な場所です。
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src={mylocation}
                      width="30px"
                      height="30px"
                      loading="lazy"
                      alt="GPSIcon"
                    />
                  </td>
                  <td>GPSで取得した現在地点です。</td>
                </tr>
              </table>
            </Typography>
          </List>
          <Divider />
          <List
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={<Box sx={titleText}>地図操作</Box>}
          >
            <Typography sx={bodyText}>
              地図の操作方法について説明します。
            </Typography>
            <List component="div" disablePadding>
              <ListItemText sx={headText} primary="地図を移動する" />
              <Typography sx={bodyText}>
                指を移動したい方向にスライドさせることで、地図がスクロールされます。
              </Typography>
              <ListItemText sx={headText} primary="地図を拡大する" />
              <Typography sx={bodyText}>
                2本の指を広げるようにスライドさせることで、地図が拡大されます。
              </Typography>
              <ListItemText sx={headText} primary="地図を縮小する" />
              <Typography sx={bodyText}>
                2本の指をつまむようにスライドさせることで、地図が縮小されます。
              </Typography>
            </List>
          </List>
          <Divider />
          <List
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={<Box sx={titleText}>レイヤの表示</Box>}
          >
            <Typography sx={bodyText}>
              地図上に表示されている危険地点、交通規制、自転車通行空間の凡例が表示されます。
              <br />
              上記の項目に加え地図上の道路、目標物名称の表示/非表示を切り替えられます。
              <br />
              <img
                src={LayerView}
                width="280px"
                loading="lazy"
                style={{ verticalAlign: "bottom" }}
                alt="レイヤの表示"
              />
            </Typography>
            <List component="div" disablePadding>
              <ListItemText sx={headText} primary="凡例" />
              <Typography sx={bodyText}>
                地図上の記号や図形が何を示しているかを表示しています。
              </Typography>
              <ListItemText
                sx={headText}
                primary="レイヤ表示/非表示の切り替え"
              />
              <Typography sx={bodyText}>
                地図上の危険地点、交通規制、自転車通行空間や道路、目標物名称の表示/非表示を切り替えられます。
              </Typography>
            </List>
          </List>
          <Divider />
          <List
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={<Box sx={titleText}>みんなの意見</Box>}
          >
            <Typography sx={bodyText}>
              「みんなの意見」とは、危険地点がどのように危険なのか、意見を集める機能です。
              挙げられた理由の中から、その通り！と感じる項目があれば、「そう思う」ボタンで賛同してください。
            </Typography>
            <List component="div" disablePadding>
              <ListItemText sx={headText} primary="みんなの意見を表示する" />
              <Typography sx={bodyText}>
                地図上の危険地点マーカーをタップすることで、その危険地点に対する、みんなの意見が表示されます。
                各異見には、賛同数がカウントされていますので、数値の大きな項目ほど、その危険性が高いと言えます。
              </Typography>

              <ListItemText sx={headText} primary="危険な理由に賛同する" />
              <Typography sx={bodyText}>
                危険な理由に対し、賛同できる項目がある場合は、「そう思う」をタップしてください。また、もう一度「そう思う」をタップすることで、賛同を取り下げることができます。
                <br />
                <img src={image2} width="280px" loading="lazy" alt="a" />
                <br />
                <img
                  src={agreeButton}
                  height="20px"
                  loading="lazy"
                  style={{ verticalAlign: "bottom" }}
                  alt="そう思う"
                />{" "}
                の項目は賛同している状態です。
                <br />
                <img
                  src={disagreeButton}
                  height="20px"
                  loading="lazy"
                  style={{ verticalAlign: "bottom" }}
                  alt="そう思う"
                />{" "}
                の項目は賛同していない状態です。
              </Typography>
            </List>
            <Divider />
            <List
              sx={{ width: "100%", bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={<Box sx={titleText}>危険地点の登録</Box>}
            >
              <Typography sx={bodyText}>
                現在、危険地点が登録されていない場所に、新しく危険地点を登録することができます。登録は以下の手順で行います。
              </Typography>
              <List component="div" disablePadding>
                <ListItemText sx={headText} primary="1. 登録を開始する" />
                <Typography sx={bodyText}>
                  画面右下の
                  <img
                    src={addIcon}
                    width="20px"
                    height="20px"
                    loading="lazy"
                    style={{ verticalAlign: "bottom" }}
                    alt="a"
                  />
                  ボタンをタップする、もしくはサイドメニューの「危険地点を登録する」を選択することで、危険地点の登録が開始されます。
                </Typography>
                <ListItemText sx={headText} primary="2. 位置を調整する" />
                <Typography sx={bodyText}>
                  登録を開始すると、地図の中心に
                  <img
                    src={userHazardouspointIcon}
                    width="20px"
                    height="20px"
                    loading="lazy"
                    style={{ verticalAlign: "bottom" }}
                    alt="a"
                  />
                  マーカーが固定表示されます。地図をスクロールすることで、登録位置の微調整ができます。
                </Typography>
                <ListItemText
                  sx={headText}
                  primary="3. 危険地点の登録理由を選択する"
                />
                <Typography sx={bodyText}>
                  登録する危険地点がどのような理由で危険なのかを、1つ以上選択します。（複数選択可能）
                </Typography>
                <Typography sx={bodyText}>
                  <img src={image3} width="280px" loading="lazy" alt="a" />
                </Typography>
                <Typography sx={bodyText}>
                  選択後、
                  <img
                    src={image5}
                    height="26px"
                    loading="lazy"
                    style={{ verticalAlign: "bottom" }}
                    alt="登録する"
                  />
                  ボタンを選択すると、登録できます。
                </Typography>
              </List>
            </List>
          </List>
        </List>
      </Box>
    </Modal>
  );
};

HelpDialog.defaultProps = {
  onClose: undefined,
  onShowHelp: undefined,
};

export default HelpDialog;
