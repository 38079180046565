import { useCallback, VFC, useState } from "react";
import {
  AppBar,
  Toolbar,
  Stack,
  Typography,
  SxProps,
  Theme,
  ThemeProvider,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import HelpIcon from "@mui/icons-material/Help";
import ColorTheme from "../themes/TrafficSafetyUserTheme";

export type CenterMenuProps = {
  label: string;
  icon?: Element | null | undefined | JSX.Element;
  uid?: string;
};

type HeaderMenuProps = {
  mapName: string;
  onConfirm?: (isSideMenu: boolean) => void;
  onHelp?: (isSideMenu: boolean) => void;
};

const HeaderMenu: VFC<HeaderMenuProps> = function HeaderMenuContent(
  props: HeaderMenuProps
) {
  const { mapName, onConfirm, onHelp } = props;
  const [headerWidth] = useState<string>(`${window.innerWidth}px`);

  const Confirm = useCallback(() => {
    onConfirm?.(true);
  }, [onConfirm]);

  const Help = useCallback(() => {
    onHelp?.(true);
  }, [onHelp]);

  const mapSxProps: SxProps<Theme> = {
    width: headerWidth,

    zIndex: 1001,
  };

  return (
    <ThemeProvider theme={ColorTheme}>
      <AppBar position="static" elevation={0} sx={mapSxProps}>
        <Toolbar
          disableGutters
          id="header"
          // variant="dense"
          sx={{
            display: "flex",
            minHeight: "1px !important",
            height: "100%",
            alignItems: "flex-start",
            justifyContent: "center",
            backgroundColor: "primary",
            color: "contrastText",
          }}
        >
          <Stack
            direction="row"
            alignItems="stretch"
            justifyContent="space-between"
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
            }}
          >
            <IconButton
              aria-label="saidmenu"
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                height: "100%",
                borderRadius: 0,
                left: "0.3rem",
                marginTop: "0.6rem",
                marginBottom: "0.6rem",
              }}
              onClick={Confirm}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h5"
              noWrap
              sx={{
                paddingLeft: 1,
                paddingRight: 1,
                margin: "auto",
                marginTop: "1rem",
              }}
            >
              {mapName}
            </Typography>
            <IconButton
              aria-label="help"
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                height: "100%",
                borderRadius: 0,
                right: "0.3rem",
                marginTop: "0.6rem",
                marginBottom: "0.6rem",
              }}
              onClick={Help}
            >
              <HelpIcon />
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

HeaderMenu.defaultProps = {
  onConfirm: undefined,
  onHelp: undefined,
};

export default HeaderMenu;
