import { useState, useEffect, useContext, createContext } from "react";
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";

type User = {
  name: string;
  password: string;
};

export type AuthStatus =
  | "connected"
  | "not_authorized"
  | "connecting"
  | "unknown";

const useMQPlatformProvideAuth = () => {
  const [uuid, setUuid] = useState<string | null>();
  const [user, setUser] = useState<User | null>(null);
  const [status, setStatus] = useState<AuthStatus>("unknown");
  const [mapboxAccessToken, setMapboxAccessToken] = useState<string>(
    "pk.eyJ1IjoidWVraSIsImEiOiJjbDU3eDBnY2QwaG9zM2ZsZnI3NDV5eDhhIn0.dW9dKOzJBrRTcRL7KeZrpA"
  );

  const domain = `${window.location.protocol}//${window.location.host}`;
  // const domain = "http://localhost:7071";

  useEffect(() => {
    switch (status) {
      case "connected":
        break;
      case "connecting":
        break;

      case "not_authorized":
      case "unknown":
        // eslint-disable-next-line no-case-declarations
        const mapboxToken =
          process.env.MAPBOX_ACCESS_TOKEN ??
          process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ??
          "";
        setMapboxAccessToken(mapboxToken);
        setStatus("not_authorized");

        // ローカルストレージに固有IDを設定
        if (localStorage.getItem("TrafficSafety")) {
          setUuid(localStorage.getItem("TrafficSafety"));
        } else {
          const id = uuidv4();
          localStorage.setItem("TrafficSafety", id);
          setUuid(localStorage.getItem("TrafficSafety"));
        }
        break;
      default:
        break;
    }
  }, [status]);

  const login = () => {
    setStatus("connecting");
  };

  const logout = () => {
    setUser(null);
    setStatus("not_authorized");
  };

  return [
    {
      user,
      status,
      uuid,
      mapboxAccessToken,
      domain,
    },
    { login, logout },
  ] as const;
};

export type TrafficSafetyAuthContext = [
  {
    user: User | null;
    uuid: string | null | undefined;
    status: AuthStatus;
    mapboxAccessToken: string | null | undefined;
    domain: string;
  },
  {
    login: (loginUser: User) => void;
    logout: () => void;
  }
];

const authContext = createContext<Readonly<TrafficSafetyAuthContext>>([
  {},
  {},
] as TrafficSafetyAuthContext);

export const TrafficSafetyAuthProvider = function TrafficSafetyAuthProvider({
  children,
}: {
  children: React.ReactElement;
}): JSX.Element {
  const auth = useMQPlatformProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = (): Readonly<TrafficSafetyAuthContext> =>
  useContext(authContext);
