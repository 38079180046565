// /src/templates/DashboardTemplate.tsx
import React, { VFC, useCallback, useState, ChangeEvent } from "react";
// import { Link } from 'react-router-dom';
import {
  Divider,
  List,
  ListItem,
  FormControlLabel,
  IconButton,
  Modal,
  Box,
  Typography,
  ListSubheader,
  Grid,
  Button,
  Checkbox,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import "../Help.css";

type TermsOfUseDialogProps = {
  isSideMenu: boolean;
  onClose?: (sideMenu: boolean) => void;
};

const TermsOfUseDialog: VFC<TermsOfUseDialogProps> = function TermsOfUseDialog(
  props: TermsOfUseDialogProps
) {
  const { isSideMenu, onClose } = props;

  const [hndlChk, setHndlChk] = useState<boolean>(false);

  const Close = useCallback(() => {
    if (localStorage.getItem("AcceptanceOfTermsOfUse")) {
      onClose?.(false);
    }
  }, [onClose]);

  const Agreement = useCallback(() => {
    onClose?.(false);
  }, [onClose]);

  const style = {
    position: "absolute",
    top: "5%",
    left: "5%",
    width: "90%",
    height: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
  };

  const bodyText = {
    fontSize: 13,
    pl: 4,
    marginBottom: "10px",
    marginRight: "32px",
  };

  const listSubheader = { fontSize: "1.5em" };

  const clearIconStyle = {
    borderRadius: "3px",
    position: "absolute",
    top: "5px",
    left: `0px`,
    display: !localStorage.getItem("AcceptanceOfTermsOfUse") ? "none" : "",
  };

  const acceptanceStyle = {
    display: localStorage.getItem("AcceptanceOfTermsOfUse") ? "none" : "",
  };

  const cancelStyle = {
    display: !localStorage.getItem("AcceptanceOfTermsOfUse") ? "none" : "",
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // イベントが存在するか
    if (event) {
      setHndlChk(event.target.checked);
    }
  };

  return (
    <Modal
      open={isSideMenu}
      onClose={Close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} id="box">
        <List
          sx={{ overflow: "scroll", height: "90%" }}
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              sx={listSubheader}
            >
              <ListItem>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      利用規約
                    </Typography>
                  </Grid>
                </Grid>

                <IconButton
                  color="inherit"
                  aria-label="menu"
                  onClick={Close}
                  sx={clearIconStyle}
                >
                  <ClearIcon />
                </IconButton>
              </ListItem>
            </ListSubheader>
          }
        >
          <Divider />
          <IconButton
            color="inherit"
            aria-label="menu"
            onClick={Close}
            sx={clearIconStyle}
          >
            <ClearIcon />
          </IconButton>
          <Divider />
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
            }}
          >
            <Typography sx={bodyText}>
              この利用規約（以下，「本規約」といいます。）は，豊橋市，豊橋技術科学大学都市・交通システム研究室，株式会社マップクエスト（以下，「提供者」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。
              <br />
            </Typography>
            <Typography sx={bodyText}>
              第1条（適用）
              <br />
              本規約は，ユーザーと提供者との間の本サービスの利用に関わる一切の関係に適用されるものとします。
              提供者は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
              本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。
            </Typography>
            <Typography sx={bodyText}>
              第2条（本規約への同意）
              <br />
              ユーザーは，本利用規約に同意頂いた上で，本サービスを利用できるものとします。
            </Typography>
            <Typography sx={bodyText}>
              第3条（本情報の内容等に係る免責）
              <br />
              本サービスの目的は，ユーザーの安全に資する情報を提供することであり，ユーザーが本情報に従って行動することによって，事故等の発生を防止することを保証するものではありません。
              また，本情報の正確性，妥当性，有用性，最新性，安全性および信頼性について，提供者および基本情報提供元はいかなる保証も行いません。
              本サービスの利用にあたり，ユーザーまたは第三者に発生した損害について，提供者および基本情報提供元は一切の責任を負いません。
              提供者は，本情報の内容を事前の予告なく変更することがあります。当該変更によって生じたユーザーまたは第三者に発生した損害について，提供者および基本情報提供元は一切の責任を負いません。
            </Typography>
            <Typography sx={bodyText}>
              第4条（提供者による投稿情報の利用）
              <br />
              提供者は，投稿情報を，本サービスでの提供およびその改良・開発，本サイトの改良，ならびに統計的に処理したうえでの分析に使用します。
              提供者は，前項に掲げる目的のため，投稿情報を提供者が認めた第三者に提供することがあります。
            </Typography>{" "}
            <Typography sx={bodyText}>
              第5条（禁止事項）
              <br />
              ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。
              <ul>
                <li>法令または公序良俗に違反する行為 犯罪行為に関連する行為</li>
                <li>
                  本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為
                </li>
                <li>
                  提供者，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
                </li>
                <li>本サービスによって得られた情報を商業的に利用する行為</li>
                <li>提供者のサービスの運営を妨害するおそれのある行為</li>
                <li>不正アクセスをし，またはこれを試みる行為</li>
                <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
                <li>不正な目的を持って本サービスを利用する行為</li>
                <li>
                  本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為
                </li>
                <li>他のユーザーに成りすます行為</li>
                <li>
                  提供者が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為
                </li>
                <li>面識のない異性との出会いを目的とした行為</li>
                <li>
                  提供者のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
                </li>
                <li>
                  自動車，オートバイ，自転車等の運転中または歩行中の本サービスの利用
                </li>
                <li>その他，提供者が不適切と判断する行為</li>
              </ul>
            </Typography>
            <Typography sx={bodyText}>
              第6条（使用上の注意）
              <br />
              自動車，オートバイ，自転車等の運転中または歩行中の本サービスの利用は大変危険です。必ず安全な場所に停車してまたは立ち止まってご利用下さい。また，自動車，オートバイ等の運転中に本情報端末の画面を注視する行為は道路交通法に違反するおそれがありますので，絶対に行わないで下さい。
              ユーザーが前項の規定に従わないで本サービスを利用したことにより事故等が発生し，ユーザーまたは第三者に損害が生じた場合にも，提供者は一切の責任を負いません。
              本サービス上で表示する交通規制等の内容は，実際のものと異なる場合があります。必ず実際の信号機，道路標識，道路標示その他の交通規制及び道路状況に従って下さい。
              本サービスを救急医療施設等への誘導用に使用しないで下さい。
              使用端末のインターネット接続環境，電波の受信状況，その他使用端末の機能上の制限等によっては，本サービスの提供を受けられない場合があります。これによりユーザーまたは第三者に損害，不利益が生じた場合であっても，提供者は一切の責任を負いません。
              提供エリアのうち一部の地域については，本情報を提供できない場合があります。
              ユーザーが本サービスを一定期間利用しなかった場合，ユーザーが本サービス上で保存した地点情報が削除される場合があります。これによりユーザーまたは第三者に損害，不利益が生じた場合であっても，提供者は一切の責任を負いません。
            </Typography>
            <Typography sx={bodyText}>
              第7条（サービス内容の変更等）
              <br />
              提供者は，ユーザーへの事前の告知をもって，本サービスの内容を変更，追加または廃止することがあり，ユーザーはこれを承諾するものとします。
            </Typography>
            <Typography sx={bodyText}>
              第8条（利用規約の変更）
              <br />
              提供者は以下の場合には，ユーザーの個別の同意を要せず，本規約を変更することができるものとします。
              <ul>
                <li>本規約の変更がユーザーの一般の利益に適合するとき。</li>
                <li>
                  本規約の変更が本サービス利用契約の目的に反せず，かつ，変更の必要性，変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるとき。
                </li>
              </ul>
              提供者はユーザーに対し，前項による本規約の変更にあたり，事前に，本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を通知します。
            </Typography>
            <Typography sx={bodyText}>
              第9条（準拠法・裁判管轄）
              <br />
              本規約の解釈にあたっては，日本法を準拠法とします。
              本サービスに関して紛争が生じた場合には，提供者の本店所在地を管轄する裁判所を専属的合意管轄とします。
            </Typography>
            <Typography sx={bodyText}>以上</Typography>
          </List>
        </List>
        <Divider />
        <Box sx={acceptanceStyle}>
          <FormControlLabel
            sx={{ left: "15px", bottom: "8%", marginLeft: 0 }}
            control={<Checkbox checked={hndlChk} onChange={handleChange} />}
            label="利用規約に同意します"
            labelPlacement="end"
          />{" "}
        </Box>
        <Box sx={acceptanceStyle}>
          <Button
            id="agree-button"
            variant="contained"
            onClick={Agreement}
            sx={{
              width: "30%",
              position: "absolute",
              bottom: "3%",
              left: "35%",
            }}
            disabled={!hndlChk}
          >
            同意する
          </Button>
        </Box>
        <Box sx={cancelStyle}>
          <Button
            onClick={Close}
            sx={{
              width: "30%",
              position: "absolute",
              bottom: "2.5%",
              left: "35%",
            }}
          >
            閉じる
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

TermsOfUseDialog.defaultProps = {
  onClose: undefined,
};

export default TermsOfUseDialog;
